.mobile-asset-section {
  position: fixed;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
  pointer-events: none;
  &.open {
    pointer-events: all;
    opacity: 1;
    .mobile-asset-content {
      bottom: 0;
    }
    .overlay {
      opacity: 1;
      pointer-events: unset;
    }
  }
  .mobile-asset-content {
    position: absolute;
    width: 100%;
    bottom: rem(-355px);
    transition: all 0.3s ease-in-out;
    z-index: 1001;
    .mobile-asset-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-image: linear-gradient(#fff, $slate-gray-100);
      height: rem(55px);
      padding: 1rem;
      .btn {
        width: max-content;
        font-size: $text-14;
        padding: 0px;
        svg {
          color: $slate-gray-800;
          font-size: $text-36;
        }
      }
      .btn-cancel { 
        text-align: left;
        color: $slate-gray-300;
      }
      .btn-ok {
        text-align: right;
        color: $blue-500;
      }
      p {
        text-align: center;
        width: 60%;
        font-size: $text-16;
        color: $slate-gray-800;
      }
    }
    .mobile-asset-body {
      background-color: #fff;
      height: rem(300px);
      overflow: auto;
    }
  }
}

.picker-container {
  .picker-highlight {
    background-color: rgba($gray-400, 0.3);
    &::after,
    &::before {
      display: none;
    }
  }
}

//profile icon select
.pf-select-container {
  .row {
    width: 100%;
    height: auto;
    .col-4 {
      .profile-img {
        margin: 0 auto;
        width: rem(100px);
        height: rem(100px);
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
      }
    }
  }
  .pf-pic {
    .row {
      list-style-type: none;
      padding: 0;
      .col-3 {
        display: flex;
        justify-content: center;
        .profile-icon {
          width: rem(62px);
          height: rem(62px);
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          cursor: pointer;
          border: rem(2px) solid transparent;
          &.selected {
            border: rem(2px) solid $blue-600;
          }
        }
        .profile-checked {
          position: absolute;
          width: rem(20px);
          height: auto;
          bottom: 0;
          right: rem(18px);
          color: $blue-600;
        }
      }
    }
    .btn-gradient-blue {
      min-width: 100%;
    }
  }
  .pf-gender {
    display: flex;
    justify-content: center;
    .btn-white,
    .btn-gradient-blue {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem(60px);
      height: rem(30px);
      border-radius: 2rem;
      margin: 1rem 0.25rem;
      svg {
        margin-right: 0;
        font-size: $text-18;
        vertical-align: bottom;
      }
      .female-icon {
        transform: rotate(45deg);
      }
    }
  }
  .line {
    text-align: center;
    color: $gray-400;
    display: block;
    &::before {
      content: "";
      background-color: $gray-400;
      width: 42%;
      height: rem(1px);
      display: inline-block;
      vertical-align: middle;
      margin-right: 2%;
    }
    &::after {
      content: "";
      background-color: $gray-400;
      width: 42%;
      height: rem(1px);
      display: inline-block;
      vertical-align: middle;
      margin-left: 2%;
    }
  }
  .ablum-select {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $slate-gray-800;
    cursor: pointer;
    &:hover {
      color: $blue-500;
    }
    span {
      margin-left: rem(8px);
    }
    svg {
      font-size: $text-30;
    }
    img {
      width: rem(24px);
      height: auto;
    }
  }
  .upload-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    .uploaded-img {
      width: 100%;
      max-width: 55%;
      height: auto;
    }
    .upload-btn {
      width: 100%;
      text-align: center;
      color: $blue-600;
      padding: rem(10px);
      border: rem(1px) solid $blue-600;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    p {
      font-size: $text-12;
    }
    .btn-gray,
    .btn-gradient-blue {
      min-width: rem(150px);
    }
  }
}

//Info centre
.ic-asset {
  .mobile-asset-header {
    padding-left: 0;
  }
  .mobile-asset-body {
    .sidebar-nav {
      .nav {
        padding: 0;
        .nav-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .uncheck,
          .checked {
            font-size: $text-20;
          }
          .uncheck {
            color: $slate-gray-200;
          }
          .checked {
            display: none;
          }
          &.active {
            border-left: 0;
            color: $slate-gray-800;
            background-color: unset;
            .uncheck {
              display: none;
            }
            .checked {
              display: block;
              color: $blue-500;

            }
          }
        }
      }
    }
  }
}

//Mobile radio select asset
.mobile-radio-select {
  padding: 0 1rem;
  .form-check {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin: 0;
    border-bottom: 1px solid $slate-gray-200;
    .form-check-label {
      font-weight: $font-medium;
      width: 100%;
      max-width: unset;
      min-width: unset;
      height: auto;
      border: 0;
      justify-content: flex-start;
      &:hover {
        box-shadow: unset;
      }
      img {
        width: rem(25px);
        height: rem(25px);
        margin-right: rem(7px);
      }
    }
    .form-check-input {
      width: 1rem;
      height: 1rem;
      &:checked[type="radio"] {
        background-image: url("../../../images/icons/mobile/checked-white.png");
        background-size: 60% 60%;
      }
    }
  }
}
