.contact-us-section {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .lazy-load-image-background.blur.lazy-load-image-loaded {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%);
    .contact-us-img {
      width: 95%;
      height: auto;
    }
  }

  .contact-us-container {
    .col-6 {
      display: flex;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      .contact-icon {
        display: block;
      }
      .contact-icon-hover {
        display: none;
      }
      .contact-content-container {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        padding: 2rem 1rem;
        .contact-content {
          color: $slate-gray-500;
          text-align: center;
          margin: 1rem 0;
        }
        img {
          width: 100%;
          max-width: 75px;
          height: auto;
          margin: 0 auto;
        }
      }
      &:hover {
        .contact-icon {
          display: none;
        }
        .contact-icon-hover {
          display: block;
          animation: fadeIn ease 1s;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

//mobile contact us
.contacts-us-m {
  .cu-m-img {
    width: 100%;
    min-width: rem(279px);
    height: auto;
  }
  .cu-content-container {
    width: 100%;
    height: rem(250px);
    background-image: url("../../../images/modal-bg.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    border-radius: rem(10px);
    cursor: pointer;
    box-shadow: $slate-gray-card-shadow;
    text-align: center;
    padding: 1rem;
    .contact-icon {
      width: rem(75px);
      height: rem(75px);
      margin-bottom: 1rem;
    }
    .cu-content {
      color: $slate-gray-500;
      p{
        height: 6rem;
        overflow: auto;
      }
    }
  }
}