.game-page-container {
  display: grid;
  gap:1.5rem;
  position: relative;
  z-index: 1;


.game-content {
  &.readMore-content {
    display: block;
    max-height: 62px;
    overflow-y: hidden;
    transition: all 1s;
    &.active {
    max-height: 200px;
    overflow-y: hidden;
    }
  }
}
  .more-btn{
    cursor: pointer;
    color: $blue-500;
  }
}

.title-img {
  width: 100%;
  max-width: 300px;
  height: auto;
}
.content-img {
  width: 100%;
  max-width: 451px;
  height: auto;
}
.game-icon-container {
  position: relative;
  border-radius: 0.75rem;
  box-shadow: $slate-gray-shadow;
  cursor: pointer;
  display: block;
  transition: all .3s ease-in-out;
  width: 100%;
  &:hover {
    box-shadow: $blue-shadow;
  }
  &.hot,
  &.hot-cn {
      &::after {
      content: '';
      position: absolute;
      top: 0;
      left: rem(-5px);
      height: 100%;
      width: 65%;
      pointer-events: none;
    }
  }
  &.hot {
    &::after {
      background: url("../../../images/hot-m.gif");
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  &.hot-cn {
    &::after {
      background: url("../../../images/hot-cn-m.gif");
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .game-icon-img {
    width: 100%;
    height: auto;
  }
}
