.vip-section,
.vip-m-section {
  .vip-card-container {
    width: 100%;
    height: auto;
    position: relative;
    .card-shadow {
      border-radius: 0.75rem;
    }

    .vip-card,
    .vip-card-mobile {
      display: flex;
      width: 100%;
      height: auto;
      position: absolute;
      top: 0%;
      left: 0;
      padding: 1rem;
      .vip-level {
        width: 35%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .vip-logo {
          width: 80%;
          height: auto;
          margin: 0 auto;
        }
        .vip-m-logo {
          width: 6rem;
          height: auto;
        }
      }
      .vip-info {
        width: 65%;
        height: auto;
        img {
          width: 22%;
          height: 100%;
          margin-right: 0.5rem;
        }
        .vip-content {
          p {
            font-size: $text-12;
          }
          .amount {
            font-size: $text-16;
            font-weight: $font-medium;
            color: $slate-gray-800;
            
          }
          span {
            font-size: $text-10;
            color: $slate-gray-500;
            font-family: $font-family-sans-serif;
          }
          .t-relegation {
            font-size: $text-10;
            color: $slate-gray-800;
            
            margin-left: 12px;
          }
        }
      }
    }
  }
  .vip-benefit {
    .benefit-header {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue-500;
      color: white;
      border-top-left-radius: rem(10px);
      border-top-right-radius: rem(10px);
      padding: 0.5rem;
      text-transform: uppercase;
      h5 {
        margin: 0;
      }
      img{
        width: rem(54px);
        height: rem(50px);
      }
    }
    .benefit-content {
      width: 100%;
      height: rem(150px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      box-shadow: $slate-gray-card-shadow;
      border-bottom-left-radius: rem(10px);
      border-bottom-right-radius: rem(10px);
      background-color: white;
      .money {
        margin: 0;
        color: $slate-gray-800;
        text-transform: uppercase;
        
      }
      .mention {
        text-align: center;
        color: $slate-gray-800;
      }
      .content {
        text-transform: uppercase;
        font-size: $text-16;
        color: $slate-gray-800;
        .number {
          color: $blue-500;
        }
      }
      .btn-outline-blue {
        height: auto;
        min-height: rem(25px);
        min-width: unset;
        line-height: 0;
        border-radius: rem(15px);
        &:hover {
          color: white;
          background: linear-gradient(to right, $blue-400, $blue-600);
        }
      }
      .footer {
        text-align: center;
        font-size: $text-12;
      }
    }
  }

  .vip-table, .vip-m-table {
    text-align: center;
    box-shadow: $slate-gray-card-shadow;
    border-radius: rem(20px);
    h3 {
      color: #fff;
      background-color: $slate-gray-500;
      margin-bottom: unset;
      padding: 1.2rem;
      border-radius: rem(20px) rem(20px) 0px 0px;
      font-size: $text-18;
    }
    table {
      border-bottom-left-radius: rem(20px);
      border-bottom-right-radius: rem(20px);
      thead {
        color: $blue-600;
        border-top: rgba(0, 0, 0, 0);
        td {
          &:first-child,
          &:last-child {
            border-width: 0;
          }
        }
      }
      td {
        padding: 1rem;
        &:first-child,
        &:last-child {
          border-width: 0;
        }
      }
      tbody {
        border-top: 1px;
        border-bottom-left-radius: rem(20px);
        border-bottom-right-radius: rem(20px);
        tr {
          &:last-child {
            border-width: 0;
          }
          td:not(:first-child) {
            
          }
        }
      }
    }
  }

  .vip-bullet-content, .vip-m-bullet-content {
    h4 {
      padding-bottom: 1rem;
      font-weight: $font-semibold;
    }
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
    }
    li {
      white-space: pre-wrap;
      counter-increment: my-awesome-counter;
      padding-bottom: 1rem;
      position: relative;
      td {
        &:nth-child(1) {
          width: 20%;
        }
      }
      &::before {
        content: counter(my-awesome-counter);
        background: $slate-gray-200;
        width: rem(25px);
        height: rem(25px);
        border-radius: 50%;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: rem(-35px);
      }
    }
  }

  .tnc-list {
    width: 100%;
    .tnc-title {
      font-weight: $font-semibold;
      margin-right: 0.25rem;
    }
  }
}

// mobile vip
.vip-m-section {
  .vip-benefit {
    background: white;
    padding: 0 1rem 1rem 1rem;

    .benefit-header {
      h5 {
        font-size: $text-14;
      }
    }
    .benefit-header {
      height: rem(68px);
    }
    .benefit-content {
      height: rem(130px);
      text-align: center;
      .money {
        font-size: $text-16;
      }
      .content {
        font-size: $text-12;
      }
      .footer, .mention {
        font-size: $text-10;
      }
    }
  }
}

.vip-m-table {
  h3{
    color: $slate-gray-400 !important;
    background-color: #E2ECFF !important;
    border-radius: 0 !important;
    font-weight: $font-regular;
  }
  .table > :not(caption) > * > * {
    border-width: rem(1px);
    border-color: $slate-gray-200;
  }
  .table-bordered > :not(caption) > * {
    border-width: rem(1rem) 0;
    border-color: $slate-gray-200;
  }
  table {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-bottom: 0;
    thead {
      td {
        color: $slate-gray-800;
        &:first-child {
          color: #BC9F65;
        }
        &:last-child {
          border-width: rem(1px) !important;
        }
      }
    }
    tbody{
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow:unset !important;
      tr {
        td{
          &:first-child {
            color: #BC9F65;
            border-width: rem(1px) !important;
          }
          &:last-child {
            border-width: rem(1px) !important;
          }
        }
      }
    }
  }
}

.vip-m-bullet-content {
  background: white;
  padding-bottom: 1rem;
  h4 {
    text-align: center;
    margin: 0;
  }
  ol{
    padding-right: 1rem;
    padding-left: 3rem;
    margin-bottom: 0;
  }
  .tnc-list {
    display: block;
    &.remark {
      color: $blue-500;
    }
  }
}