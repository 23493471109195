.anm-section {
  .marquee-col {
    // background: linear-gradient(to bottom, $blue-500, $blue-600);
    background-image: url("../../../images/anm-card.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    height: rem(41px);
    border-radius: 1.5rem;
    padding: 0.4rem;
    background-size: 100% 100%;
    img {
      width: unset;
      &:nth-last-child() {
        position: relative;
        margin: -2rem 0;
        width: 100%;
        height: auto;
        max-width: rem(30px);
      }
    }
    marquee {
      margin-bottom: 0%;
      cursor: pointer;
      margin: 0 0.75rem;
      display: flex;
      width: 100%;
      span {
        padding-right: 2rem;
      }
    }
    .more-btn-blue {
      height: rem(31px);
      line-height: rem(31px);
      width:rem(90px);
      margin-top: 0;
      padding: 0;
      background-size: 100% 100%;
      border-width: 0;
    }
  }
  .marquee-m-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    marquee {
      width: 90%;

      span {
        padding-right: 2rem;
      }
    }
  }

  .megaphone {
    font-size: $text-24;
    color: $blue-500;
  }

  .modal {
    .modal-dialog {
      flex-direction: column;
      justify-content: center;
      .btn-gradient-blue {
        min-width: rem(150px);
        border-radius: rem(20px);
        pointer-events: auto;
      }
      .modal-content {
        min-height: rem(320px);
        .modal-body {
          align-items: unset;
          justify-content: unset;
          ul {
            list-style-type: none;
            padding: 0;
            max-height: rem(265px);
            overflow: auto;
            li {
              border-bottom: 1px solid $slate-gray-200;
              margin-top: 0.5rem;
              margin-right: 0.5rem;
              font-size: $text-12;
              .ann-date {
                margin: 0.5rem 0;
                display: flex;
                align-items: center;
                svg {
                  width: rem(16px);
                  height: rem(16px);
                  color: $slate-gray-500;
                  margin-right: rem(5px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-view {
  .anm-section {
    position: relative;
    // margin-top: -2.5rem !important;
  }
}