.fes-ani-get {
  background: none;
  background-color: $green-500;
  margin-right: 1rem;
}

.fes-ani-grey-btn {
  background-color: $gray-500 !important;
  background: none;
  pointer-events: none;
  margin-right: 1rem;
}

.ani-section {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(black, 0.6), rgba(black, 1));

  .ani-content-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;

    .ani-header-wrapper {
      position: absolute;
      z-index: 2;
    }

    .ani-item-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .fes-ani-asset {
        width: rem(350px);
        height: 70%;
        object-fit: contain;
        cursor: pointer;
        // z-index: -1;

        &.mobile {
          width: 20rem;
          // width: 15rem;
        }
      }

      .flare-light {
        position: absolute;
        animation: spin 5s linear infinite;
        width: 100%;
        height: auto;
        pointer-events: none;
        transform: translate(-50%, -50%);
        top: 20%;
        z-index: -2;
        left: 0%;
      }

      .ani-burst-one,
      .ani-burst {
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: -2;
        animation: zonIn 1s linear;
        opacity: 0;
        pointer-events: none;
        left: -50%;

        &.mobile {
          width: 18rem;
        }
      }

      .ani-burst-one {
        animation-delay: 0.2s;
      }
    }

    .ani-bottom-wrapper {
      position: relative;
      color: white;

      .close-button-wrapper {
        h5 {
          cursor: pointer;
        }

        .fes-btn-close {
          border: solid 2px white;
          border-radius: 1.5rem;
          background-image: none;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  //bg here
  .fes-ani-bg-wrap {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -99;

    .rain- {

      &one,
      &two,
      &three {
        position: absolute;
        z-index: 1000;
        transform: translate(25%, -100%);
        width: 100%;
        height: auto;
        animation: coinFall 3s linear infinite;

        &.mobile {
          height: 100%;
          object-fit: cover;
        }
      }

      &one {
        animation-delay: 1s;
      }

      &two {
        animation-delay: 2s;
      }

      &three {
        animation-delay: 3s;
      }
    }

    // .fes-ani-overlay {
    //   position: fixed;
    //   opacity: 0.8;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: unset!important;
    //   background-size: 100% 100%;
    //   background-repeat: no-repeat;
    //   background-color: black;
    //   animation: shine 4s infinite linear;
    //   background-position: center;
    //   pointer-events: none;

    // }

    .fes-ani-overlay {
      position: fixed;
      opacity: 0.8;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../../../../images/home-overlay-events/angpao-bg.jpg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      animation: shine 4s infinite linear;
      background-position: center;
      pointer-events: none;

       &.mobile {
         background-image: url(../../../../images/home-overlay-events/angpao-bg-mobile.jpg);
      }
    }
  }
}




//edit here
.ani-section {
  &.cny {
    background: unset;

    .ani-content-wrapper {
      position: relative;
      height: 100%;
      text-align: center;

      .ani-header-wrapper {
        top: 17%;

        .ani-title {
          color: #ffff;

          .fes-ani-amount {
            color: $orange-100;
          }
        }
      }

      .ani-item-wrapper {
        height: 100%;
      }

      .flare-light {
        scale: 1.5;
      }

      .fes-ani-asset {
        height: auto;

      }

      .ani-burst-one,
      .ani-burst {
        left: -5%;
        top: 10%;

        &.mobile {
          left: 7%;
          scale: 1.2;
        }
      }
    }

  }

  &.raya {
    .ani-content-wrapper {
      .ani-header-wrapper {
        top: 5%;

        .ani-title {
          h1 {
            font-size: $text-48;
            color: #ffc236;
            text-shadow: rem(2px) rem(2px) rem(5px) black;
          }

          .title-bg {
            z-index: -1;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 40%;
            // width: 120%;
            height: auto;
            position: absolute;
          }
        }

        h5 {
          color: white;
          margin-top: 1rem;

          .fes-ani-amount {
            color: $orange-500;
            font-size: $text-24;
          }
        }

        &.mobile {
          .ani-title {
            h1 {
              font-size: $text-30;
            }

            .title-bg {
              width: 120%;
            }
          }

          h5 {
            margin-top: 0px;
          }
        }
      }

      .ani-item-wrapper {
        .flare-light {
          top: 10%;

          &.mobile {
            top: auto;
          }
        }

        .fes-ani-asset {
          width: 70% !important;
          object-fit: contain;
          transform: scale(1.3);

          &.mobile {
            width: 25rem !important;
            transform: unset;
          }
        }

        .fes-amount-wrap {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          margin-left: 0.5rem;

          h5 {
            color: black;
          }

          p {
            line-height: 2.5rem;
            color: #ffc236;
            font-size: $text-20;
          }

          .fes-ani-amount {
            font-size: $text-60;
          }
        }
      }

      .ani-bottom-wrapper {

        // margin-top: -15%;
        .claim-btn {
          color: white;
          font-size: $text-30;
          text-shadow: rem(2px) rem(2px) rem(5px) black;
          border: none;
          background-color: transparent;
          background-image: url(../../../../images/home-overlay-events/button.png);
          width: 10rem;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 0.2rem 1rem 1rem 1rem;
          background-position: center;

          &.mobile {
            font-size: $text-24;
          }
        }

        .close-button-wrapper {
          .fes-btn-close {
            border: solid 2px white;
            color: white;
            border-radius: 1.5rem;
            background-image: none;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    .fes-ani-bg-wrap {
      .fes-ani-overlay {
        background-image: url("../../../../images/home-overlay-events/raya-bg.png");

        &.mobile {
          background-image: url("../../../../images/home-overlay-events/raya-bg-mobile.png");
        }
      }
    }
  }

  &.dewali {
    .lantern-bg {
      z-index: -3;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 90%;
      object-fit: contain;
    }

    .ani-content-wrapper {
      .ani-header-wrapper {
        top: 17%;

        .ani-title {
          h1 {
            color: white;
          }
        }

        h5 {
          color: black;
          margin-top: 0.5rem;
        }
      }

      .ani-item-wrapper {
        transform: translate(-50%, -50%);
        top: 35%;
        left: 50%;

        .flare-light {
          scale: 1.2;
          opacity: 0.4;
          top: 50%;

          &.mobile {
            top: 56%;
          }
        }

        .fes-ani-asset {
          width: 90% !important;
          transform: scale(1);
        }

        .ani-bottom-wrapper {
          margin-top: -12.5%;

          &.mobile {
            margin-top: -10.5%;
          }

          &.dewali-margin {
            margin-top: -38.5%;

            &.mobile {
              margin-top: -38.5%;
            }
          }

          .claim-btn {
            &.mobile {
              scale: 1.2;
              margin-bottom: 0.75rem;

            }
          }
        }

        .fes-amount-wrap {
          margin-left: 0rem;
          top: 48%;
          font-size: $text-72;
          color: #FFE200;
          text-shadow: rem(1px) rem(3px) rem(6px) black;
        }
      }
    }

    .fes-ani-bg-wrap{
      .fes-ani-overlay {
        opacity: 0.5;
        background-image: url("../../../../images/home-overlay-events/dewali/raya-bg.png");
        &.mobile{
          background-image: url("../../../../images/home-overlay-events/dewali/raya-bg-mobile.png");
        }
      }
  }
  }

  &.mooncake{
    .fes-ani-asset {
      width: 30rem!important;
      height: 70%;
      object-fit: contain;
      cursor: pointer;
      // z-index: -1;

      &.mobile {
        width: 20rem;
        // width: 15rem;
      }
    }

    .fes-ani-bg-wrap {
      .fes-ani-overlay {
        background-image: url("../../../../images/home-overlay-events/mooncake/angpao-bg.webp");

        &.mobile {
          background-image: url("../../../../images/home-overlay-events/mooncake/angpao-bg-mobile.webp");
        }
      }
    }
  }
}

@keyframes coinFall {
  100% {
    transform: translate(-25%, 200%);
  }
}

@keyframes shine {
  0% {
    filter: brightness(100%);
  }

  50% {
    filter: brightness(125%);
  }

  100% {
    filter: brightness(100%);
  }
}

@keyframes zonIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  90% {
    opacity: 0;
    transform: scale(1.2);
  }
}