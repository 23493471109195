.navbar {
  height: 75px;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;

  display: unset !important;
  flex-wrap: unset !important;
  align-items: unset !important;
  justify-content: unset !important;
  &.scrolled {
    background-color: white;
    box-shadow: 0 0 15px 0px rgba($blue-600, 0.25);
  }
  > .x-container-no-m {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-brand {
      margin-right: 0;
      margin-top: -0.5rem;
      img {
        max-width: 100px;
        width: 100%;
        height: auto;
      }
    }
    .navbar-nav {
      flex-direction: row;
      .nav-item {
        margin: auto;
        padding: 0 0.35rem;
        .nav-link {
          display: block;
          position: relative;
          // font-weight: $font-medium;
          color: $slate-gray-500;
          overflow: hidden;
          &.icon {
            display: grid;
            text-align: center;
            min-width: 45px;
            img {
              width: 20px;
              height: 20px;
              margin: 0 auto 0.25rem auto;
            }
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 1px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $blue-600;
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
            transform: translate3d(-100%, 0, 0);
          }
          &:hover::after,
          &:focus::after {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
          &.active {
            color: $blue-600;
            &::after {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }
        &:hover {
          .dropdown-menu-list {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

// dropdown list css
.dropdown-menu-list {
  width: 100%;
  position: absolute;
  // top: -100%;
  top: 0%;
  transform: translateY(-150%);
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(white, 0.95),
    rgba(#d6e3f6, 0.95)
  );
  box-shadow: $slate-gray-card-shadow;
  transition: all 1s ease-in-out;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  padding: 1rem;
  background-position: bottom;
  background-size: 100% 100%;
  .navbar-expand {
    margin-top: 75px;
    .col-5ths,
    .col-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .dropdown-title {
        width: 100%;
        display: flex;
        align-items: center;
        color: $slate-gray-500;
        transition: all 0.3s ease-in-out;
        img {
          width: 30%;
          height: auto;
        }
        p {
          width: 75%;
          height: auto;
          line-height: 1.3;
          font-weight: $font-medium;
          text-transform: uppercase;
        }
      }
      .dropdown-img {
        width: 70%;
        height: 70%;
        object-fit: contain;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .dropdown-title {
          color: $blue-500;
        }
        .dropdown-img {
          transform: scale(1.1);
        }
      }

      .more-btn-blue {
        width: 80%;
        height: 3rem;
        font-size: $text-14;
        line-height: 2rem;
        border-radius: 3rem;
      }
    }
  }

  //dropdown with enter btn css
  &.sport-dropdown,
  &.p2p-dropdown,
  &.lottery-dropdown,
  &.promos-dropdown,
  &.esports-dropdown {
    .row {
      justify-content: center;
      .col-3 {
        display: block;
        min-height: 200px;
        .dropdown-title {
          width: 55%;
          display: flex;
          align-items: center;
          color: $slate-gray-500;
          img {
            width: 35%;
          }
          p {
            width: 65%;
            font-weight: $font-medium;
            line-height: 1.3;
            text-transform: uppercase;
          }
        }
        .dropdown-img {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          object-fit: contain;
          transition: all 0.3s ease-in-out;
          margin-bottom: -1rem;
          z-index: -1;
        }
        .more-btn-blue {
          width: 100px;
          height: 34px;
          line-height: 34px;
          a {
            color: white;
          }
        }
        &:hover {
          .dropdown-title {
            color: $blue-500;
          }
          .more-btn-blue {
            box-shadow: $blue-hover-shadow;
          }
          .dropdown-img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &.esports-dropdown {
    .dropdown-img {
      right: -6% !important;
    }
  }

  //p2p dropdown css
  &.p2p-dropdown {
    .dropdown-img {
      right: -6% !important;
    }
  }
  &.promos-dropdown {
    .dropdown-title {
      p {
        text-transform: unset !important;
        margin-bottom: 0.75rem !important;
      }
    }
    .dropdown-img {
      width: 80% !important;
    }
  }
}

//country and langauge dropdown
.language-select {
  display: flex;
  align-items: center;
  font-size: $text-14;
  margin-left: 1rem;
  img {
    width: 25px;
    height: 25px;
  }
  .arrow {
    margin-top: -4px;
  }
}

//user balance css
.user-balance-container {
  display: flex;
  flex-direction: column;
  .user-deposit {
    margin-bottom: 5px;
    color: $slate-gray-500;
    span {
      border-right: 1px solid;
      padding-right: 0.75rem;
      font-size: 12px;
    }
    img {
      padding-left: 0.75rem;
      width: 32px;
      height: auto;
    }
  }
  .user-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-medium;
    color: $slate-gray-800;
    cursor: pointer;
    .coin-balance {
      display: flex;
      align-items: center;
      justify-content: end;
      position: relative;
      padding-right: 0.25rem;
      img {
        width: 100%;
        max-width: rem(20px);
        height: auto;
      }
      span {
        padding-left: 0.25rem;
      }
      &::after {
        content: "";
        height: 1.25rem;
        width: rem(1px);
        position: absolute;
        right: rem(-0.5px);
        top: 50%;
        transform: translateY(-50%);
        background-color: $slate-gray-800;
      }
    }
    .header-arrow {
      width: 16px;
      height: 16px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(-180deg);
      &.active {
        transform: rotate(0deg);
      }
    }
  }
  .profile-dropdown {
    width: 225px;
    position: absolute;
    top: 50px;
    right: 0;
    list-style-type: none;
    background-color: white;
    box-shadow: $slate-gray-card-shadow;
    border-radius: 10px;
    padding: 1rem 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    &.active {
      pointer-events: visible;
      opacity: 1;
    }
    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 60%;
      border-bottom: 6px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    li {
      padding: 1rem;
      a {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          padding: 0 0.5rem;
          color: $slate-gray-500;
          &:hover {
            color: $blue-500;
          }
        }
      }
    }
    .btn-outline-white {
      display: block;
      width: 100%;
    }
  }
}

.user-profile-container {
  position: relative;
  width: rem(50px);
  height: rem(50px);
  .user-profile-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
  .notify-red-dot{
    z-index: 2;
  }
  .vip-icon {
    position: absolute;
    bottom: 0%;
    right: -22%;
    background: $slate-gray-400;
    z-index: 2;
  }
  .avatar-border-img{
    position: absolute;
    top: -22%;
    left: -22%;
  }
}

.user-profile-container,
.ps-user-profile {
  .react-loading {
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $slate-gray-500;
    div {
      width: rem(30px) !important;
      height: rem(30px) !important;
    }
  }
}

.badge {
  display: inline-block;
  padding: 0;
  width: rem(25px);
  height: rem(17px);
  border-radius: 0.5rem;
  color: white;
  background-image: linear-gradient(0deg, $red-500, #f7778c) !important;
  font-size: $text-10;
  font-weight: $font-medium;
  line-height: rem(17px);
}

//download app banner mobile
.download-app-banner {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  img {
    width: rem(42px);
    height: rem(42px);
  }
  .dla-close {
    font-size: 1.75rem;
  }
  .btn-gradient-blue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $text-10;
    height: 1.5rem;
    width: max-content;
    min-width: unset;
    padding: 0 .25rem;
  }
  h5{
    font-size: $text-14;
    color: $slate-gray-800;
    font-weight: $font-semibold;
  }
  p{
    font-size: $text-10;
  }
}

//side bar mobile
.side-bar-mobile {
  width: 100%;
  height: rem(40px);
  position: relative;
  // z-index: 99;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   top: -2%;
  //   left: 0;
  //   height: 3.5rem;
  //   width: 100%;
  //   background: linear-gradient(
  //     to bottom,
  //     $slate-gray-100,
  //     rgba(255, 255, 255, 0)
  //   );
  //   pointer-events: none;
  // }
  .sb-toggle-btn {
    height: auto;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    z-index: 1;
    svg {
      color: $blue-400;
      font-size: $text-24;
    }
    .notify-red-dot {
      width: rem(10px);
      height: rem(10px);
      top: -14%;
      left: 65%;
    }
  }
  .sb-logo {
    width: 100%;
    max-width: rem(60px);
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  // .sb-login-register{
  //   position: absolute;
  //   right: 1rem;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   font-size: $text-12;
  //   color: $slate-gray-400;
  //   z-index: 1;
  //   border-radius: 0.25rem;
  // }

  .language-btn-m{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .sb-menu {
    width: rem(300px);
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: rem(-300px);
    background: white;
    background-image: url("../../images/side-bar-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 100;
    transition: all 0.5s ease;
    overflow: auto;
    .pi-m-info {
      width: 100%;
      height: 7rem;
      padding: 0 1.5rem;
      .user-info {
        display: flex;
        align-items: center;
      }
      svg {
        margin-left: auto;
        font-size: 1.5rem;
        color: $slate-gray-800;
      }
    }
    .sb-banner {
      width: 100%;
      height: 7rem;
      display: flex;
      align-items: center;
      padding: 0 1.5rem;
      img {
        width: 100%;
        max-width: rem(79px);
        height: auto;
      }
    }
    .sb-list {
      width: 100%;
      height: auto;
      list-style-type: none;
      padding: 0 0.5rem;
      li {
        border-bottom: 1px solid $slate-gray-200;
        padding: 1rem 0.5rem;
        &:last-child {
          border-bottom: 0;
        }
        a {
          display: flex;
          align-items: center;
          color: $slate-gray-500;
          img {
            width: rem(27px);
            height: rem(27px);
          }
          img,
          .badge {
            margin: 0 0.5rem;
          }
        }
      }
    }
    .sb-btn {
      display: grid;
      padding: 0 1rem;
      .btn {
        border-radius: rem(5px);
        width: 100%;
      }
    }
    &.open {
      left: 0;
    }
    @include hideScrollbars;
  }
}

//mobile tab nav
.nav {
  &.mobile {
    padding: 0.5rem;
    margin: 1rem;
    background: #e0dddd;
    border-radius: rem(10px);
    .nav-link,
    .nav-item {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #b0b0b0;
      border-radius: rem(10px);
      a {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 1.2;
      }
      &.active {
        color: $slate-gray-800;
        background: white;
      }
    }
  }
}
