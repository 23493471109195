.ambassador-section {
  .ambassador-title {
    height: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: inherit;
    color: $blue-500;
    top: 7.5%;
    left: 1rem;

    h1,
    h2 {
      font-weight: $font-semibold !important;
      margin-bottom: 0;
    }
  }
}

.events-section {
  position: relative;

  .events-video-container {
    position: relative;

    // .card-shadow {
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     pointer-events: none;
    //     bottom: 0;
    //     left: 0;
    //     height: 10%;
    //     width: 100%;
    //     background: linear-gradient(
    //       to bottom,
    //       rgba(255, 255, 255, 0),
    //       $slate-gray-100
    //     );
    //   }
    // }

    .events-video-content {
      padding: 2rem;
      background-image: url("../../../images/home/video-bg.webp");
      background-size: 100% 100%;
      background-position: bottom;
      background-repeat: no-repeat;

      .col-9 {
        p {
          height: 85px;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .uefa-slider{
        height: rem(250px)!important;
        &::after{
          display: none!important;
        }
      }
      .col-3,
      .col-4,
      .uefa-slider {
        height: rem(597px);
        position: relative;

        // overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          pointer-events: none;
          bottom: -4%;
          left: 0;
          height: 15%;
          width: 100%;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 35%,
            rgba(232, 242, 250, 0.85),
            rgba(232, 242, 250, 1)
          );
        }

        ul {
          height: 100%;
          list-style-type: none;
          padding-left: unset;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          li,
          .uefa-slider-content {
            padding: 2px;
            margin-bottom: 2px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &.selected {
              border: 3px solid $blue-500;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                transform: translate(-50%, -50%);
                background-image: url("../../../images/videos/selected-arrow.svg");
                background-size: contain;
                background-repeat: no-repeat;
                top: 50%;
                left: 0;
                width: 25px;
                height: 25px;
              }
            }

            // &:hover {
            //   transform: scale(1.025);
            // }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }

            &:hover .overlay,
            &:focus .overlay,
            &:active .overlay {
              opacity: 1;
            }

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              transition: 0.5s ease;
              background-color: rgba($slate-gray-800, 0.25);

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              svg {
                color: $slate-gray-100;
                font-size: $text-48;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.global-section {
  .section-image-container {
    .sub-img1 {
      height: auto;
      width: 100%;
      max-width: 107px;
      position: absolute;
      bottom: 13%;
      left: 31%;
      animation: top 3s infinite ease-in-out;
    }

    .sub-img2 {
      height: auto;
      width: 100%;
      max-width: 69px;
      position: absolute;
      left: 47.5%;
      bottom: 42%;
      animation: top 3s infinite ease-in-out;
    }

    .sub-img3 {
      height: auto;
      width: 100%;
      max-width: 90px;
      position: absolute;
      top: 18%;
      left: 0%;
      animation: top 3s infinite ease-in-out;
    }

    .section-content-container {
      width: 70%;
      margin-left: 30%;
    }
  }
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }

  50% {
    transform: translate(15px);
  }

  100% {
    transform: translate(-15px);
  }
}

@keyframes top {
  0% {
    transform: translate(0, -15px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -15px);
  }
}

.service-section {
  .deco-5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .service-border-image {
    width: 100%;
    height: auto;
    max-width: rem(140px);
    position: absolute;
    top: -3%;
    left: 50%;
    transform: translateX(-50%);
  }

  .service-section-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../images/home/es-bg.webp");
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.75rem 1.25rem;

    img {
      width: 100%;
      max-width: 100px;
      height: auto;
    }

    h4 {
      color: $slate-gray-500;
      margin: 1rem 0;
    }

    p {
      height: 100%;
      text-align: center;
      color: $slate-gray-500;
      padding: 0 1rem 1rem 1rem;
      overflow: auto;
    }
  }
}

.gauge-col {
  .col-3 {
    text-align: center;

    h5 {
      font-size: $text-14;
      color: $slate-gray-300;
    }

    .gauge-wrapper {
      position: relative;
      width: 100%;
      margin: 0 auto 1rem auto;
      text-align: center;

      .gauge-img {
        width: 165px;
        height: auto;
      }

      .gauge-content {
        position: absolute;
        width: 125px;
        height: 125px;
        top: 13.5%;
        left: 50%;
        transform: translateX(-50%);

        .gauge-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 70%;
          position: absolute;
          left: 50%;
          top: 52.5%;
          transform: translate(-50%, -50%);

          p {
            font-size: 0.5rem;
            white-space: nowrap;
            margin-bottom: 0;

            &:last-child {
              font-size: $text-12;
            }
          }

          .gauge-value {
            font-size: $text-60;
            font-weight: $font-medium;
            line-height: 1;
            color: $blue-500;
          }
        }
      }
    }
  }
}

.event-countdown-section {
  .col-6 {
    position: relative;

    .countdown-timer {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      .countdown-digit-container {
        .countdown-digit {
          .digit-container {
            section {
              span {
                margin: 0 5px;
                border-radius: 5px;
                box-shadow: $slate-gray-card-shadow;

                span {
                  margin: 0;
                  padding: 0;
                  font-size: $text-36 !important;
                  font-weight: $font-semibold;
                }
              }
            }
          }
        }
      }
    }

    .enter-btn-blue {
      position: absolute;
      bottom: 10%;
      right: 14%;
    }
  }
}

.events-section {
  &.live-sport-event {
    .col-8 {
      .btn-gradient-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        min-width: 16.25rem;
      }
    }
    .col-4,
    .uefa-slider {
      ul,
      .uefa-wrap {
        .uefa-slider-content{
          margin-bottom: unset!important;
          margin: 0rem 0.25rem!important;
          .team-vs{
            overflow: unset!important;
          }
        }
        li,
        .uefa-slider-content {
          margin-bottom: 0.75rem !important;
          .event-sport-detail {
            width: 100%;
            height: auto;
            background-image: url("../../../images/home/event-sport-bg.webp");
            // background-image: url("../../../images/home/uefa/event-sport-bg.png");
            background-position: right;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0.5rem;
            transition: all 0.25s ease-in-out;
            color: $slate-gray-500;
            &.card-shadow {
              &:hover {
                box-shadow: 0 4px 12px 0 rgba($blue-500, 0.25);
              }
            }
            .event-sport-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1rem;
              background: $blue-500;
              color: white;
              .team-vs {
                width: 55%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .info-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                background: white;
                color: $blue-500;
              }
            }
            .event-sport-content {
              padding: 1rem;
              .team-1,
              .team-2 {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                span {
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  width: 1.5rem;
                  height: 1.5rem;
                  font-weight: $font-bold;
                  color: white;
                  border-radius: 100%;
                  margin-right: 0.5rem;
                  flex-wrap: wrap;
                }
              }
              .team-1 {
                span {
                  background: $green-500;
                }
              }
              .team-2 {
                span {
                  background: $red-500;
                }
              }
              .event-sport-bet {
                display: flex;
                justify-content: space-between;
                .sport-hdp {
                  width: 48%;
                  height: auto;
                  display: flex;
                  padding: 0.5rem;
                  border: 1px solid rgba($blue-500, 0.25);
                  border-radius: 0.5rem;
                  background: white;
                  justify-content: space-between;
                  line-height: 1;
                  span {
                    color: $slate-gray-300;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//product overview section
.product-ctg {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("../../../images/home/product-ctg-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  list-style: none;
  padding: 0.5rem;
  .product-ctg-btn {
    width: max-content;
    min-width: 8rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    .ctg-img,
    .active-ctg-img {
      width: rem(32px);
      height: auto;
      margin-right: 0.25rem;
      opacity: 1 !important;
    }
    .ctg-img {
      display: block;
    }
    .active-ctg-img {
      display: none;
    }
    &.active {
      background-image: url("../../../images/home/btn-more-bg.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: white;
      .active-ctg-img {
        animation: zonIn 0.25s;
        display: block;
      }
      .ctg-img {
        display: none;
      }
    }
  }
}
