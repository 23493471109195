// Redemption History //
.rh-tp-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $slate-gray-200;

  .nav-link {
    border: unset;
    color: $slate-gray-700;

    &.active {
      display: inline-block;
      color: $blue-500;
      border-bottom: 2px solid $blue-500;
      padding-bottom: 0.5rem;
      margin-top: auto;
      // margin-bottom: 0;
    }
  }

  & > div {
    display: flex;
    max-width: 70%;
    padding-bottom: 0.5rem;

    p {
      font-size: $text-12;
    }

    img {
      margin-right: 7px;
    }
  }
}

.pd-card-col {
  .pd-table {
    &.rh-pd-table {
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
    }

    .rdp-his-body {
      position: relative;
      margin-bottom: 1rem;
      display: table;
      width: 100%;
      border:rem(1px) solid #becaec;
      border-radius: 0.5rem;
      border-top: rem(1px) solid #becaec;

      // &:not(:last-child):after {
      //   content: "";
      //   display: block;
      //   height: 1rem;
      // }

      &.active,
      &.pending {
        .input-group-text,
        label {
          display: none;
        }
      }

      .rdp-his-title {
        background: linear-gradient(#fff, $slate-gray-100);

        td {
          border-bottom: unset;
          border-top: rem(1px) solid #becaec;
          text-align: unset;

          &:first-child {
            // border-left: rem(1px) solid #becaec;
            border-top-left-radius: 0.5rem;
          }

          &:last-child {
            // border-right: rem(1px) solid #becaec;
            border-top-right-radius: 0.5rem;
          }
        }
      }

      .rdp-his-content {
        border-top: unset;

        td {
          width: 25%;
          border-bottom: unset;
          // border-bottom: 1px solid #becaec;
          text-align: unset;
          white-space: break-spaces;

          h6 {
            font-weight: $font-medium;
          }

          // &:first-child {
          //   border-bottom-left-radius: 10px;
          //   border-left: 1px solid #becaec;
          // }

          // &:last-child {
          //   border-right: 1px solid #becaec;
          //   border-bottom-right-radius: 10px;
          // }

          .mall-track-title {
            display: inline-flex;

            p {
              margin-top: 0.25rem;
              line-height: $leading-normal;
              width: calc(100% - 2rem);
            }
          }
        }
      }

      .product-img {
        width: 3.5rem;
        height: auto;
        margin-right: 1rem;
      }

      .product-coin {
        width: 1.5rem;
        margin-right: 0.3rem;
        margin-bottom: 0.2rem;
      }

      .icon-img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.pd-card-col,
.pd-m-card-col {
  .active {
    color: $green-500;
  }

  .pending {
    color: $orange-500;
  }

  .deactive {
    color: $red-500;
  }

  .interest-tnc > p {
    color: $red-500;
  }
}

//mobile view
.mobile-view {
  .pd-m-card-col {
    box-shadow: unset;
    &:not(.white) {
      background-color: unset;
    }
    .rh-m-pd-card {
      background-color: white;
      padding: 1rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .rh-m-tp-panel {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        svg {
          margin-right: .5rem;
        }
        p {
          font-size: $text-12;
        }
      }
      .rh-m-md-panel {
        display: flex;
        align-items: center;
        .rh-m-md-img {
          border-radius: .5rem;
          background: linear-gradient(to bottom, white, $slate-gray-100);
          box-shadow: 0 4px 12px 0 rgba($slate-gray-500, .5);
          width: 4rem;
          padding: .5rem;
          img {
            width: 3rem;
            height: 3rem;
            object-fit: contain;
          }
        }
        .rh-m-md-content { 
          margin-left: 1rem;
        }
      }
      .rh-m-btm-panel {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
       .rh-m-btm-status {
        display: flex;
        align-items: center;
        width: 65%;
        p {
          font-size: $text-12;
          padding-right: .5rem;
          width: calc(100% - 2rem);
        }
        svg {
          margin-right: .5rem;
        }
       }
       button {
        width: 35%;
       }
      }
    }
  }
}

.ep-modal {
  .modal-dialog {
    max-width: 1200px;

    .ep-address {
      width: 100%;

      .modal-content {
        background-image: unset;
        background-color: unset;

        .pd-card-col {
          background: $blue-100;

          .pd-table {
            thead {
              tr {
                background: white;
              }
            }

            tbody {
              background: white;
            }
          }

          .wdrawal-add-bank {
            background: white;
          }

          .react-select__single-value {
            .btn {
              display: none;
            }
          }
        }
      }
    }
  }
}
