// Global Buttons //
.btn-blue,
.btn-white,
.btn-gradient-blue,
.btn-outline-blue,
.btn-outline-red {
  min-width: rem(80px);
  font-size: 0.875rem;
  // font-weight: $font-medium;
  white-space: nowrap;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 1rem;
  line-height: 1;
  svg,
  img {
    margin-right: 0.5rem;
  }
}

.btn-blue {
  color: white!important;
  background: linear-gradient(to bottom, $blue-500, $blue-600);
  box-shadow: $blue-shadow;
  &:hover {
    color: white;
    box-shadow: $blue-hover-shadow;
  }
}

.btn-white {
  background: linear-gradient(to bottom, white, $slate-gray-100);
  box-shadow: $slate-gray-shadow;
  &:hover {
    box-shadow: $slate-gray-hover-shadow;
  }
  &.active {
    color: white;
    background: linear-gradient(to bottom, $blue-500, $blue-600);
  }
}

.more-btn-blue {
  background-image: url("../../../images/home/btn-more-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  border-radius: 1.25rem;
  display: inline-block;
  font-weight: $font-medium;
  font-size: $text-18;
  width: rem(120px);
  height: rem(42px);
  line-height: rem(42px);
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: $blue-hover-shadow;
    color: #fff;
  }
}

.enter-btn-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(190px);
  height: rem(65px);
  box-shadow: $blue-shadow;
  border-radius: 2.75rem;
  transition: box-shadow 0.3s ease-in-out;
  background-image: url("../../../images/home/btn-enter-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  font-size: $text-20;
  color: white;
  line-height: 1;
  text-align: center;
  border: 0;
  &:hover {
    color: white;
    box-shadow: $blue-hover-shadow;
  }
}

.btn-gradient-blue {
  height: rem(40px);
  min-width: rem(100px);
  font-weight: $font-medium;
  border-radius: 0.25rem;
  color: white;
  background: linear-gradient(to right, $blue-400, $blue-600);
  &:hover {
    color: white;
    box-shadow: $blue-hover-shadow;
  }
  &:disabled,
  &[disabled] {
    color: white;
    background: $gray-400 !important;
  }
}

.btn-gray {
  height: rem(40px);
  min-width: rem(100px);
  font-weight: $font-medium;
  border-radius: 0.25rem;
  color: white;
  background: $gray-400;
  &:hover {
    color: white;
    box-shadow: $slate-gray-hover-shadow;
  }
  &:disabled,
  &[disabled] {
    color: white;
    background: $gray-400 !important;
  }
}

.btn-outline-blue {
  height: rem(40px);
  min-width: rem(120px);
  border-radius: 0.25rem;
  color: $blue-500;
  border: 1px solid $blue-500;
  &:hover {
    color: $blue-500;
    box-shadow: $blue-hover-shadow;
  }
}

.btn-outline-white {
  height: rem(40px);
  min-width: rem(120px);
  border-radius: 0.25rem;
  color: $slate-gray-200;
  border: 1px solid $slate-gray-200;
  &:hover {
    border: 1px solid $blue-500;
    color: $blue-500;
    box-shadow: $blue-hover-shadow;
  }
}

.btn-outline-red {
  height: rem(40px);
  min-width: rem(120px);
  border-radius: 0.25rem;
  color: $red-500;
  border: 1px solid $red-500;
  &:hover {
    color: $red-500;
    box-shadow: $red-hover-shadow;
  }
  &:focus {
    box-shadow: $red-hover-shadow;
  }
}

.btn.icon {
  display: inline-block;
  width: rem(30px);
  height: rem(30px);
  padding: 0.25rem;
  margin: 0 0.5rem;
  color: $slate-gray-500;
  font-size: $text-18;
  svg {
    display: block;
    margin: auto;
    width: 100%;
    height: auto;
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    box-shadow: $blue-hover-shadow;
  }
  &.red {
    color: $red-500;
  }
  &.blue {
    color: $blue-500;
  }
  &.gradient-blue {
    color: white;
    background: linear-gradient(to right, $blue-400, $blue-600);
  }
}

.btn.text-icon {
  display: flex;
  align-items: center;
  color: $blue-500;
  font-weight: $font-semibold;
  padding: 0.25rem;
  transition: opacity 0.3s ease-in-out;
  svg,
  img {
    width: rem(25px);
    height: auto;
    margin-right: 7px;
  }
  &:hover {
    opacity: 0.75;
  }
}

.btn.trash {
  color: $red-500;
}

.btn-text-icon-gray {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  color: $gray-300;
  fill: currentColor;
  svg,
  img {
    margin-right: 0.5rem;
    font-size: $text-20;
  }
  @include no-focus-hover($gray-100);
}

.arrow {
  border: solid #878ea6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 5px;
  margin-left: 2px;
}
.down {
  transform: rotate(45deg);
}

.btn.hm-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.75rem;
  height: rem(40px);
}

//back to top btn
.btt-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 2rem;
    margin: 0 auto;
    border-radius: 6rem;
    color: $slate-gray-300;
    svg {
      font-size: $text-18;
      margin: 0;
    }
  }
}

//tnc btn & text
.tnc-btn {
  display: block;
  min-width: 4rem;
  height: 1.75rem;
  font-size: $text-10;
  padding: 0;
  text-align: center;
  margin: auto 0 auto 0.5rem;
}
.tnc-text {
  display: inline-block;
  color: $blue-500;
  font-size: $text-12;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 0 0.25rem;
  :hover {
    text-decoration: underline;
  }
}

//reset btn
.reset-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(25px);
  height: rem(25px);
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: 0.5rem;
  &:hover {
    box-shadow: $blue-hover-shadow;
  }
  img {
    width: rem(22px);
    height: rem(22px);
    &.spin {
      animation: spin 1s linear;
    }
  }
}

.btn-max {
  min-width: rem(60px);
  height: 2rem;
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

