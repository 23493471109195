.mobile-page-header-section {
  display: flex;
  justify-content: space-between;
  width:100%;
  align-items: center;
  .mobile-page-header-left{
    width: 45%;
    .btn-gradient-blue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p{
      position: relative;
      padding-bottom:0.2rem;
    }
    .vip-icon{
      margin-left:0.3rem;
    }
    h2{
      color:$slate-gray-800;
      margin-bottom:0px;
      img{
       margin: 0rem 0rem 0.3rem 0.3rem;
       height: rem(20px);
       width: rem(20px);
      }
    }
  }
  .mobile-page-header-right {
    ul {
      display: flex;
      gap: .5rem;
      padding-left: 0;
      margin-bottom:0;
      li {
        list-style-type: none;
      }
      a,
      .mphr-link {
        display: grid;
        width: 3.5rem;
        padding: 0;
        text-align: center;
        color: $slate-gray-500;
        font-size: $text-12;
        background: unset;
        border: unset;
        img {
          pointer-events: none;
          margin: 0 auto;
          width: rem(36px);
          height: auto;
        }
      }
    }
  }
}
