// Typography
$font-family-sans-serif: 'AdihausDIN', 'Poppins','Noto Serif SC', sans-serif;
// $font-adihaus-sans-serif: 'AdihausDIN', sans-serif;
// $font-yahei-sans-serif: 'Microsoft YaHei Lobby UI';
// $font-yahei-ui-sans-serif: 'MicrosoftYaHeiUI';
$font-size-base: 0.875rem;
$line-height-base: 1.5;
$body-color: #6c7ba8;

$html-font-size: 16px;
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

//
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'slick-carousel/slick/slick-theme.css';
@import 'slick-carousel/slick/slick.css';

//Image Lazy Loaded
@import 'react-lazy-load-image-component/src/effects/blur.css';
//// Swiper
// @import 'swiper/swiper-bundle.css';

// Variables
@import 'partials/_variables';
@import 'partials/_mixin';
@import 'partials/_base';
@import 'partials/_navbar';
@import 'partials/_footer';

// Global Styles
@import 'master';
@import 'partials/global/_buttons.scss';
@import 'partials/global/_forms.scss';
@import 'partials/global/_table.scss';
@import 'partials/global/_section.scss';
@import 'partials/global/_swiper.scss';
@import 'partials/global/_modal.scss';
@import 'partials/global/_sweet-alert';

///Components
@import 'partials/components/_home';
@import 'partials/components/_gauge';
@import 'partials/components/_announcement';
@import 'partials/components/_games-list';
@import 'partials/components/_games-page';
@import 'partials/components/_game-page-content';
@import 'partials/components/_certificate';
@import 'partials/components/_promos';
@import 'partials/components/_about-us';
@import 'partials/components/_info-centre';
@import 'partials/components/_responsible-gaming';
@import 'partials/components/_register';
@import 'partials/components/_contact-us';
@import 'partials/components/_vip';
@import 'partials/components/_mobile-bottom-nav';
@import 'partials/components/_mobile-page-header';
@import 'partials/components/_mobile-assets';
@import 'partials/components/_miscellaneous';
@import 'partials/components/_mobile-region-language';
@import 'partials/components/_right-fixed-btn';
@import 'partials/components/_sponsor';
@import 'partials/components/events/_lucky-spin';
@import 'partials/components/events/_bet-and-earn';
@import 'partials/components/_download-app';
@import 'partials/components/_check-in';
@import 'partials/components/leaderboard';
@import 'partials/components/_world-cup';
@import 'partials/components/_live-streaming';
@import 'partials/components/events/_home-events';
@import 'partials/components/events/_event-theme';
@import 'partials/components/_premier_league';

// Profile Dashboard
@import 'partials/components/profile/_profile';
@import 'partials/components/profile/_personal-information';
@import 'partials/components/profile/_vip-exclusives';
@import 'partials/components/profile/_my-wallet';
@import 'partials/components/profile/_mall';
@import 'partials/components/profile/_redemption-history';
@import 'partials/components/profile/_messages';
@import 'partials/components/profile/_referral';
@import 'partials/components/profile/_feedback';
@import 'partials/components/profile/_withdrawal';
@import 'partials/components/profile/_bonus';


