// Mall //
.mall-promos-col {
  .col-6 > a {
    display: block;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
    overflow: hidden;
    &:hover {
      box-shadow: $blue-hover-shadow;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.mall {
  &.global-modal {
    .modal-dialog {
      max-width: 800px;
      margin: auto !important;
    }
  }
  .mall-banner {
    width: 100%;
    height: 18rem;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 1rem;
    box-shadow: $slate-gray-card-shadow;
  }

  .filters-col {
    margin-top: 0rem;
    .nav-item {
      &:nth-child(n + 1):not(:last-child) {
        margin-right: 2rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }

  .filter-body {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .reset-btn {
      display: unset !important;
    }
    .btn {
      width: 11rem;
      // margin-top: 1rem;
      &.active {
        color: $blue-600;
      }
    }
    .filter-content {
      width: 20rem;
      h6 {
        margin-bottom: 0.5rem;
      }
      // .btn-gradient-blue {
      //   width: 50%;
      //   margin: 0 auto;
      // }
    }
    .input-range {
      width: 90%;
      margin-left: 0.5rem;
      .input-range__slider,
      .input-range__track--active {
        background: linear-gradient(to right, $blue-400, $blue-600);
        border: unset;
      }
      .input-range__label--min,
      .input-range__label--max {
        display: none;
      }
      .input-range__track {
        height: 0.5rem;
      }
      .input-range__slider {
        height: 0.8rem;
        width: 0.8rem;
        margin-top: -0.6rem;
      }
      .input-range__label-container {
        // left: -93%;
        font-size: $text-12;
        &::before {
          content: "";
          background-image: url("../../../../images/mall/coin.svg");
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          float: left;
          background-size: 1rem;
        }
      }
      .input-range__label--value {
        top: 0.5rem;
        font-family: $font-family-sans-serif;
        color: #000;
      }
    }
  }

  .product-title {
    display: inline-flex;
    justify-content: space-between;
    p {
      svg {
        color: $blue-600;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.3rem;
      }
      .notify-red-dot {
        top: 0.8rem;
        right: 2%;
        border: unset;
        height: 1rem;
        width: 1rem;
        small {
          color: white;
          position: absolute;
          top: rem(0px);
          right: rem(4px);
        }
      }
    }
  }

  .product-body {
    text-align: center;
    background: linear-gradient(#fff, $slate-gray-100);
    box-shadow: $slate-gray-card-shadow;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    &:hover {
      color: $blue-500;
      box-shadow: $blue-hover-shadow;
    }
    .product-cont {
      padding: 1rem;
      cursor: pointer;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 1rem !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product-details {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      padding: 0.5rem 1rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      align-items: center;
      .product-coin {
        width: rem(20px);
        margin-right: 0.2rem;
      }
    }
    p {
      text-align: left;
    }
  }

  //mallCheckout
  &.checkout {
    .table {
      .product-img {
        // height: 3rem;
        // width: 30%;
        height: 100%;
        width: 100%;
        object-fit: contain ;
      }
      .product-coin {
        margin-right: 0.5rem;
      }
      &.cart {
        tr {
          td {
            &:nth-child(2) {
              img {
                // height: 5rem;
                // width: 5rem;
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
        td {
          input {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
    .delete-icon {
      width: rem(15px);
    }
    .btn-gradient-blue {
      width: rem(150px);
    }
    .select-phone-ck {
      width: 11rem;
    }
    .ck-title {
      background-color: $blue-100;
      padding: 1rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      h6 {
        margin-bottom: 0rem;
        font-size: $text-12;
      }
    }

    //mallNoRecords
    .no-records-cont {
      margin: 10rem;
      .no-records {
        img {
          max-width: 25rem;
        }
      }
      .btn-gradient-blue {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
    .submit-btn {
      width: 15rem;
      display: inline-grid;
      align-items: center;
    }
  }
  //mallCart
  &.cart {
    .input-group-col {
      margin-bottom: 10rem;
    }
  }

  .fav-btn {
    img {
      width: rem(25px);
    }
  }

  .count-btn {
    border: rem(1px) solid $gray-400;
    padding: 0rem 0.5rem;
    margin: 1rem 0rem;
    border-radius: unset;
    &:focus {
      box-shadow: unset;
    }
    &.left {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }
    &.center {
      border-left: unset;
      border-right: unset;
      border-radius: unset;
    }
    &.right {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }

  .f-orange {
    color: $orange-500;
    display: inline-flex;
    align-items: center;
    .product-coin {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  //mall-modal
  .fav-btn {
    border: 0;
    background-color: unset;
  }
  .modal-content {
    border: unset;
  }
  .modal-header {
    background: linear-gradient(#fff, $slate-gray-100);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .modal-title {
    color: #000 !important;
    font-weight: 400 !important;
    display: inline-flex;
    img {
      margin-right: 0.5rem;
      width: 1.25rem;
      height: auto;
    }
  }
  .modal-body {
    padding: 1rem 0;
    background-color: #fff;
    // border-bottom-left-radius: 1rem;
    // border-bottom-right-radius: 1rem;
    flex-direction: row !important;
    align-items: flex-start !important;
    display: flex !important;
    .modal-body-cont {
      width: 50%;
      padding: 0 1rem;
      display: grid;
      &.border-left {
        border-left: rem(1px) solid rgba($slate-gray-200, 1);
      }
      .btn-gradient-blue {
        margin-bottom: 0.5rem;
      }
      .product-broder {
        margin: 2rem,
      }
      .product-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      .details-cont {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        small {
          line-height: 2;
        }
      }
      .product-tnc {
        font-size: $text-12;
        // overflow-y: scroll;
        // height: 100px;
        padding-right: 0.5rem;
        &::-webkit-scrollbar-thumb {
          border-radius: rem(20px);
        }
        &::-webkit-scrollbar {
          width: rem(3px);
        }
      }
    }
    .f-red {
      color: $red-500;
    }
    .product-coin {
      width: 1rem;
      height: auto;
      margin-bottom: 0.2rem;
      margin-right: 0.1rem;
    }
  }
  .modal-footer {
    padding: 1rem;
    background-color: #fff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top: rem(1px) solid rgba($slate-gray-200, 1);
  }
  .feedback {
    font-size: $text-12;
    color: $red-500;
    text-align: center;
  }
}

//failed-will-show
.select-page-modal {
  h5 {
    color: #000;
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
  }
  .modal-dialog {
    width: rem(300px);
    ul {
      padding-left: 0;
      .btn-outline-blue {
        text-align: left;
        width: 100%;
        margin-bottom: 0.5rem;
        &.active {
          color: white;
          background-color: $blue-500;
        }
        .game-icon {
          width: rem(25px);
        }
      }
    }
  }
  .btn-gradient-blue,
  .back-btn {
    width: 80%;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//modal-address-form
.modal-address-form {
  .modal-footer {
    border-top: 0;
  }
  .btn-gradient-blue {
    width: rem(200px);
    margin: 0 auto;
  }
}

//checkout mobile
.mobile-view {
  .mall {
    .mall-header {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url("../../../../images/mall/mobile-bg.jpg");
        // background-position: 100% 0%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .xmall-m-amount {
        padding: 1rem;
        img {
          width: 100%;
          height: auto;
        }
        .amount {
          font-size: $text-16;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3rem;
          .left {
            display: flex;
            align-items: center;
            svg {
              zoom: 1.5;
              color: $slate-gray-200;
            }
          }
          .right {
            width: 1.3rem;
            position: relative;
            .notify-red-dot {
              top: -10%;
              right: -30%;
            }
          }
          .number {
            color: $slate-gray-700;
          }
        }
        .earning {
          font-size: $text-12;
          .number {
            color: $green-500;
          }
        }
      }
    }
    .pf-fade-col {
      .pd-card-col {
        padding: 0.75rem;
        border-radius: 0.5rem;
        .product-body {
          p {
            font-size: $text-12;
          }
          .product-cont {
            padding: 0.5rem;

            p {
              margin-bottom: 0.5rem !important;
            }
          }
          .product-details {
            padding: 0.5rem;
            .fav-btn {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
    .x3m-m-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .btn-m-filter {
        border: 3px solid $slate-gray-300;
        border-radius: 1rem;
        width: 6rem;
        padding: 0.3rem;
        font-size: $text-12;
        &.active {
          border-color: $blue-500;
          color: $blue-500;
        }
      }
    }
    .filter-body{
      align-items: center;
      margin-bottom: 2rem;
      img{
        width: 1.4rem;
        height: 1.4rem;
      }
      .btn-success{
        width: 5rem;
        height: 2rem;
        border-radius: 2rem;
        background-color: $green-500;
        border-color: $green-500;
      }
    }

    .details-title {
      font-size: $text-14;
    }
    .product-broder {
      border-radius: 0.5rem;
      border: 1px solid $slate-gray-200;
      margin: 0rem !important;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.95),
        rgba(#d6e3f6, 0.95)
      );
      box-shadow: 0 4px 12px 0 rgba($slate-gray-500, 0.25);
      width: 5rem;
      text-align: center;
      padding: 0.5rem;
      .product-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .accordion {
      margin-top: 0.5rem;
      .accordion-body {
        padding: 0.5rem 0rem;
      }
      .accordion-item {
        border: unset;
      }
      .accordion-button {
        padding: 1rem 1rem 0rem 1rem;
        display: flex;
        justify-content: center;
        &::after {
          margin-left: 0.3rem;
          width: 1rem;
          height: 1rem;
          background-size: 1rem;
        }
        &:not(.collapsed) {
          background-color: unset;
          box-shadow: unset;
          padding: 1rem 1rem 0rem 1rem;
        }
        &:focus {
          box-shadow: unset;
        }
      }
    }
  }

  .checkout {
    .select-phone-ck {
      width: 10rem;
      .react-select__control {
        border: unset !important;
        .react-select__value-container {
          padding: 0rem;
        }
        .react-select__single-value {
          img {
            margin-right: 0.2rem;
          }
        }
      }
    }
    .form-check {
      width: 100%;
    }
    .form-check-label {
      height: unset;
      &:not(.wdrawal-add-bank) {
        min-height: rem(50px);
        > * {
          flex: unset;
          &:nth-child(1) {
            display: unset !important;
            align-items: center;
            padding-right: 1rem;
          }
        }
      }
    }
    .btn-gradient-blue {
      width: unset;
    }
    .checkout-product {
      &:not(:last-child) {
        &::after {
          content: "";
          display: block;
          left: 0;
          right: 0;
          border-top: rem(2px) solid $slate-gray-100;
          margin: 0.5rem auto;
        }
      }
      .details-cont {
        p {
          color: #000;
        }
      }
    }
    .f-orange {
      font-size: $text-16;
    }
    .mall-fade-col {
      position: fixed;
      bottom: 0rem;
      width: 100%;
      .mall-fade-body {
        background: linear-gradient(to bottom, #ffff, #d6e3f6);
      }
      .mall-fade-cont {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-top: rem(1px) solid $slate-gray-200;
      }
    }
  }

  .mall-track {
    .mall-track-head {
      padding: 1rem 1rem 1rem 0rem;
      background: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-bottom: 1px solid $slate-gray-200;
    }
    .mall-track-title {
      label {
        width: unset;
      }
    }
    .right {
      padding: 0rem 3.5rem;
      .vip-lvl-col {
        .vip-lvl-md {
          margin: 2rem 0rem;
        }
        .track-details-r {
          width: 16rem !important;
        }
      }
    }
  }
}
