// Global Forms//
.forms-col {
  display: grid;
  gap: 1rem;
}
.col-form-label {
  color: $slate-gray-800;
  line-height: 27px;
  small {
    color: $slate-gray-200;
  }
}
.col-form-value {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  line-height: 27px;
}
.form-control,
.form-select {
  color: $slate-gray-800;
  border: 1px solid $slate-gray-200;
  min-height: rem(40px);
  // &:focus,
  // &:focus-within,
  // .is-valid:focus,
  // &:valid:focus,
  // .is-invalid:focus,
  // &:invalid:focus  {
  //   box-shadow: none;
  // }
  &::placeholder {
    color: $slate-gray-200;
  }
  &.disabled,
  &[disabled] {
    outline: 0 !important;
    border: 0 !important;
    background: white;
  }
  &[readOnly] {
    color: $slate-gray-500;
    background-color: $blue-100;
    & + .input-group-text {
      background-color: $blue-100;
    }
  }
}
.input-group {
  min-height: rem(40px);
  // &:focus-within {
  // .form-control {
  //   box-shadow: none !important;
  //   &:-webkit-autofill,
  //   &:-webkit-autofill:hover,
  //   &:-webkit-autofill:focus,
  //   &:-webkit-autofill:active{
  //       -webkit-text-fill-color: $slate-gray-800 !important;
  //       -webkit-box-shadow: 0 0 0 30px white inset !important;
  //       transition: background-color 5000s ease-in-out 0s;
  //   }
  // }
  // border-radius: 0.25rem;
  // box-shadow: $blue-hover-shadow;
  // span,
  // .react-select__control {
  //   border-color: #86b7fe !important;
  // }
  // }
  &.right {
    .react-select__control {
      min-width: rem(150px);
      &--is-focused {
        box-shadow: none !important;
      }
    }
    // .form-control {
    //   border-right: 0 !important;
    // }
    span,
    .react-select__control {
      border-left: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  &.left {
    .react-select__control {
      min-width: rem(150px);
      &--is-focused {
        box-shadow: none !important;
      }
    }
    // .form-control {
    //   border-left: 0 !important;
    // }
    span,
    .react-select__control {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .input-group-text {
    color: $slate-gray-800;
    background: transparent;
    position: relative;
    .btn.icon {
      width: rem(20px);
      height: rem(20px);
      margin: 0 !important;
      padding: 0 !important;
      box-shadow: none;
    }
    svg {
      font-size: 1rem;
      color: $slate-gray-300;
    }
    &.edit {
      border-color: $blue-500;
      background-color: white;
      svg {
        color: $blue-500 !important;
      }
    }
    // .btn-gradient-blue {
    //   &.show-copied {
    //     position: absolute;
    //     top: -80%;
    //     left: -38%;
    //     padding: 0;
    //     width: 4rem;
    //     min-width: unset;
    //     height: 2.25rem;
    //     &:disabled {
    //       background: linear-gradient(
    //         to right,
    //         $blue-400,
    //         $blue-600
    //       ) !important;
    //       opacity: 1;
    //     }
    //   }
    // }
  }
}

// Global Checked with Icon //
.form-check-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.5rem;
  &.deposit-amts {
    .form-check-label {
      min-width: rem(70px);
    }
  }
  .form-check-inline {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
.form-check {
  margin-right: 0.5rem;
  &.form-check-inline {
    padding-left: 0;
  }
  .form-check-input {
    &:checked {
      background-color: $blue-500;
      border-color: $blue-500;
    }
  }
  .btn-check {
    border-color: $slate-gray-200;
    &:active,
    &:focus {
      & + .form-check-label {
        box-shadow: none !important;
      }
    }
    &:checked {
      background-color: $blue-500;
      border-color: $blue-500;
      + .form-check-label {
        color: $blue-500 !important;
        border: rem(1px) solid $blue-500;
        position: relative;
        overflow: hidden;
        &::after {
          content: "";
          position: absolute;
          background: url("../../../images/profile/icons/checked.svg");
          background-repeat: no-repeat;
          background-size: contain;
          bottom: rem(-1px);
          right: rem(-1px);
          width: rem(25px);
          height: rem(25px);
          // z-index: 1;
        }
        .provider-img {
          filter: none;
        }
      }
    }
  }
  .form-check-label {
    height: rem(40px);
    min-width: rem(120px);
    max-width: rem(140px);
    line-height: $leading-tight;
    border: rem(1px) solid $slate-gray-200;
    color: $slate-gray-800;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: $blue-hover-shadow;
    }
    img,
    svg {
      height: rem(22.5px);
      max-width: 4.5rem;
      // &:not(:only-child) {
      //   margin-right: rem(7px);
      // }
    }
    .provider-img {
      filter: grayscale(0.5);
    }
  }
}

// Global Filter with Icon //
.filters-col {
  display: grid;
  gap: 0.75rem;
  margin-top: 1rem;
  .filters-tp-panel,
  .filters-bt-panel {
    display: flex;
    justify-content: space-between;
    .filters-tp-col,
    .filters-bt-col {
      display: flex;
      align-items: center;
      .filters-group {
        display: flex;
        .form-check.filter {
          margin-right: 0;
          &:nth-child(n + 2) {
            margin-left: rem(-1px);
          }
          &:only-child > label {
            border-radius: 0.25rem !important;
          }
          &:first-child > label {
            border-radius: 0.25rem 0 0 0.25rem;
          }
          &:last-child > label {
            border-radius: 0 0.25rem 0.25rem 0;
          }
          label {
            line-height: $leading-tight;
            min-width: rem(75px) !important;
            font-size: $text-12;
            border-radius: 0;
            &:hover {
              box-shadow: $blue-hover-shadow;
            }
          }
        }
      }
      .col-form-label {
        font-size: $text-12;
        white-space: nowrap;
        margin-right: 1rem;
        &.wrap-text {
          white-space: normal;
          line-height: 1;
          max-width: 3.25rem;
          text-align: end;
        }
      }
      button {
        margin-left: rem(7px);
        font-size: $text-12;
      }
      .form-control {
        font-size: $text-12;
      }
      .input-group {
        width: auto;
      }
      .filter-provider {
        width: rem(222px);
        .react-select__menu {
          .react-select__option {
            img {
              filter: grayscale(1);
            }
            p {
              font-size: $text-12;
              font-weight: normal;
              color: $slate-gray-800;
            }
            &:hover {
              img {
                filter: none;
              }
              p {
                color: $blue-500;
              }
            }
          }
        }
        .react-select__placeholder {
          font-size: $text-12;
        }
      }
    }
  }
}
.filters-m-col {
  box-shadow: $slate-gray-card-shadow;
  .filters-m-panel {
    position: relative;
    display: grid;
    gap: 1rem;
    background-color: white;
    padding: 1rem;
    margin-top: -1rem;
    .filter-m-tp-col {
      display: flex;
      margin: 0 -0.5rem;
    }
    .filter-m-btm-col {
      position: absolute;
      display: none;
      opacity: 0;
      z-index: 1;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      padding: 1rem;
      border-top: rem(1px) solid $slate-gray-200;
      background-color: white;
      .btn-gradient-blue {
        width: 100%;
      }
      .btn-success {
        height: 2.5rem;
        background-color: $green-500;
        margin-top: 1rem;
        font-weight: $font-medium;
        &:hover {
          box-shadow: 0 0 0 0.25rem rgba($green-500, 0.25);
        }
      }
    }
    .filter-m-cat {
      width: 50%;
      padding: 0 0.5rem;
      .filter-m-label {
        width: 30%;
        &.provider-label {
          font-size: $text-10;
        }
      }
      .btn-m-filter {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        height: 100%;
        color: $slate-gray-800;
        border-radius: 1rem;
        border: rem(1px) solid $slate-gray-200;
        &.date,
        &.provider {
          p {
            font-size: $text-12;
            line-height: 1.2;
            text-align: center;
            overflow: unset;
          }
        }
        &.provider {
          text-align: start;
        }
        &.active {
          border-color: $blue-500;
          color: $blue-500;
        }
        p {
          text-align: left;
          width: 80%;
          overflow: hidden;
        }
        svg {
          font-size: 1.25rem;
        }
      }
    }
    .filters-m-date-group {
      .filter-m-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        &:not(:last-child) {
          border-bottom: rem(1px) solid $slate-gray-200;
        }
        .btn-m-filter-date {
          display: flex;
          align-items: center;

          color: $slate-gray-800;
          padding-right: 0;
          svg {
            color: $slate-gray-200;
            font-size: 1.25rem;
          }
        }
      }
    }
  }
  &.show {
    position: relative;
    z-index: 95;
    .filter-m-btm-col {
      display: block;
      opacity: 1;
    }
    & ~ .overlay {
      opacity: 1;
      pointer-events: unset;
    }
  }
}
.pf-m-dashboard {
  .filters-m-group,
  .filters-m-date-group {
    margin: 0 -0.25rem;
    .form-check-inline.filter {
      width: 25%;
      margin: 0;
      padding: 0.25rem;
      .form-check-label {
        color: $slate-gray-200;
        font-size: $text-12;
        max-width: unset;
        min-width: unset;
        &.provider-m-check {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 3.5rem;
          font-size: $text-10;
          font-weight: $font-semibold;
          img {
            width: 1.25rem;
            height: 1.25rem;
            margin: 0;
          }
        }
      }
    }
  }
}

// Global Drop Down //
.react-select__control {
  color: $slate-gray-800;
  border: rem(1px) solid $slate-gray-200 !important;
  height: rem(40px);
  border-radius: 0.25rem !important;
  // &:hover {
  //   border: 1px solid $slate-gray-200 !important;
  // }
  &--is-focused {
    border-color: #86b7fe !important;
    box-shadow: $blue-hover-shadow !important;
  }
  .react-select__value-container {
    padding: rem(2px) rem(8px);
  }
  .react-select__indicator {
    padding: rem(8px);
    svg {
      width: rem(20px);
      height: rem(20px);
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__single-value {
    display: flex;
    align-items: center;
    img {
      width: rem(25px);
      height: rem(25px);
      object-fit: contain;
      margin-right: rem(14px);
    }
  }
  .react-select__placeholder {
    margin-left: rem(2px);
    margin-right: rem(2px);
    color: $slate-gray-200;
  }
  .react-select__input > input {
    caret-color: transparent;
  }
}
.react-select__menu {
  width: 100%;
  box-shadow: $slate-gray-card-shadow;
  z-index: 10 !important;
  .react-select__menu-list {
    max-height: rem(220px);
    overflow: auto;
  }
  .react-select__option {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 0;
    font-weight: $font-medium;
    color: $slate-gray-500;
    background-color: white;
    &:hover {
      background-color: $blue-100;
    }
    img {
      width: rem(25px);
      height: rem(25px);
      object-fit: contain;
      margin-right: rem(14px);
    }
  }
}

// Global Date Range Picker //
.daterangepicker {
  select {
    border-radius: 0.25rem;
    color: $slate-gray-500;
    border-color: $slate-gray-200;
    background-color: transparent;
  }
  td.off,
  td.off.in-range,
  td.off.start-date,
  td.off.end-date {
    color: $slate-gray-200 !important;
  }
  td.in-range {
    color: $slate-gray-500 !important;
    background-color: $blue-100 !important;
  }
  td.active,
  td.active:hover {
    color: white !important;
    background-color: $blue-500 !important;
  }
  .calendar-table .next span,
  .calendar-table .prev span {
    border: solid $slate-gray-500 !important;
    border-width: 0 1px 1px 0 !important;
  }
}

// Global Files Dropzone //
.dropzone-container {
  aside {
    padding-top: calc(0.375rem + 1px);
    .btn.icon {
      color: $red-500;
    }
  }
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem;
    min-height: rem(100px);
    border: 2px dashed $slate-gray-200;
    border-radius: 0.25rem;
    font-weight: $font-medium;
    background-color: $blue-100;
    p {
      font-size: $text-12;
    }
    svg {
      display: block;
      font-size: $text-30;
      color: $slate-gray-200;
      margin: 0 auto;
    }
    span {
      color: $blue-500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// Validation
.invalid-feedback,
.text-danger {
  font-size: $text-12;
  color: $red-500 !important;
}

.valid-feedback {
  color: $green-500 !important;
}

// PIN Code
.pin-field {
  background-color: white;
  border: 1px solid $slate-gray-200;
  border-radius: 0.25rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 3rem;
}

.pin-field:focus {
  border-color: $blue-500;
  outline: none;
  transform: scale(1.05);
}

.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: $red-500;
  box-shadow: 0 0 0.25rem rgba($red-500, 0.5);
}

swd-pin-field {
  display: block;
  margin: 0 auto;
}

// swd-pin-field[completed] .pin-field {
//   border-color: $green-500;
//   background-color: rgba($green-500, 0.25);
// }

@keyframes shake {
  from {
    transform: scale(1.05) translateY(-5%);
  }
  to {
    transform: scale(1.05) translateY(5%);
  }
}
