.master-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;

  &.cny {
    height: 120vh;
  }
  &.uefa{
    position: absolute;
    bottom: 5%;
    top:unset;
  }
}

.events-side-deco {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 17.5%;
    height: auto;
    z-index: 10;
    pointer-events: none;
    &.left {
      left: 0;
      top:40%;
    }
    &.right {
      right: 0;
      top: -2.5%;
    }
}

.events-section {
  .events-video-container {
    overflow: unset !important;

    .border-deco {
      position: absolute;
      width: 102%;
      height: 106%;
      top: -2%;
      right: -1%;
      z-index: 1;
    }
  }

  .events-deco-image {

    .deco-1,
    .deco-2,
    .deco-3 {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 2;
      pointer-events: none;
    }

    // &.christmas {
    //   .deco-1 {
    //     max-width: rem(486px);
    //     bottom: 0;
    //     left: -18%;
    //   }
    //   .deco-2 {
    //     max-width: rem(180px);
    //     bottom: -6%;
    //     left: -10%;
    //     z-index: 1;
    //   }
    //   .deco-3 {
    //     max-width: rem(486px);
    //     bottom: 0;
    //     right: -14%;
    //   }
    // }
    &.cny {
      .deco-1 {
        max-width: 10rem;
        bottom: -7%;
        left: -3%;
      }

      .deco-2 {
        max-width: rem(283px);
        bottom: -10%;
        right: 0;
      }
    }

    &.raya {
      .deco-1 {
        max-width: rem(116px);
      }
    }

    &.mooncake {
      .deco-1 {
        max-width: 30%;
        transform: translate(50%, -50%);
        top: 18%;
        left: -25%;
        z-index: -1;
      }

      .deco-2 {
        width: 100%;
        max-width: 25%;
        transform: translate(50%, -50%);
        bottom: -95%;
        right: -7%;
        z-index: -1;
      }
    }

    &.halloween {
      .halloween-deco-3 {
        position: absolute;
        width: 15%;
        bottom: -16%;
        left: -6%;
        z-index: 1;
      }
    }

    &.christmas {
      .christmas-deco-3 {
        position: absolute;
        // width: 15%;
        bottom: 0%;
        left: -6%;
        z-index: 1;
      }
    }
  }
}

.global-section {
  overflow-x: hidden;

  &.event {
    .section-image-container {
      .sub-img1 {
        left: 50%;
        bottom: 8%;
        max-width: rem(120px);
      }

      .sub-img2 {
        left: 60%;
        max-width: rem(82px);
      }

      .sub-img3 {
        left: 23%;
        max-width: rem(102px);
      }

      .section-img {
        max-width: 42.75rem;
        // margin-top: -2rem;
        margin-left: 4rem;
      }

      &.mooncake {
        .sub-img1 {
          left: 54%;
        }

        .sub-img2 {
          left: 70%;
        }

        .sub-img3 {
          left: 37%;
        }

        .section-img {
          max-width: 48.75rem;
          margin-top: -6rem;
        }
      }

      &.merdeka {
        top: -15%;

        .sub-img3 {
          top: 40%;
        }
      }

      &.halloween {
        .section-img {
          margin-left: 9rem;
          margin-top: 3rem;
        }

        .sub-img1 {
          left: 70%;
        }

        .sub-img2 {
          left: 93%;
        }

        .sub-img3 {
          left: 70%;
        }
      }

      &.christmas {
        .sub-img2 {
          left: 65%;
        }

        .sub-img3 {
          left: 33%;
        }

        .section-img {
          max-width: 38rem;
          margin-left: 14rem;
        }

        .christmas-tree {
          position: absolute;
          transform: translate(50%, -50%);
          width: 55%;
          bottom: -44%;
          left: -20%;
          z-index: -1;
        }
      }

      &.cny {
        .section-img {
          max-width: 47.75rem;
          margin-top: -1rem;
        }

        .sub-img1 {
          left: 60%;
        }

        .sub-img2 {
          left: 70%;
        }

        .sub-img3 {
          left: 35%;
        }
      }

      &.uefa {
        .section-img {
          max-width: 37.75rem;
          margin-top: -1rem;
          margin-left: 8rem;
        }
      }
      &.anni {
        .section-img {
          max-width: 45rem;
          margin-top: -8rem;
          margin-left: 8rem;
        }
      }
    }

    .section-content-container {
      overflow: unset;

      .mooncake,
      .halloween {
        max-width: 60%;
      }

      .halloween-deco-3 {
        position: absolute;
        width: 15%;
        top: -9%;
        right: -7%;
        transform: scaleX(-1);
      }

      .border-deco {
        width: 102%;
        height: 106%;
        top: -2%;
        right: -1%;
      }

      .sub-img4 {
        width: 100%;
        height: auto;
        max-width: rem(134px);
        position: absolute;
        top: 1%;
        right: -6%;
      }

      .deco-4 {
        position: absolute;
        top: -8%;
        left: 0;
      }

      &.cny {
        background-image: url("../../../../images/home/chinese/wwr-bg.webp");
        width: 54.6rem;

        .deco-4 {
          top: 0%;
          width: 10rem;
          left: 88%;
        }
      }
    }

    .product-overview-slick {
      .slick-container {
        .slick-track {
          .product-overview {
            .card-shadow {
              display: block;
            }

            .product-overview-bg {
              &.active {
                display: none;
              }
            }
          }

          .slick-center {
            .product-overview {
              .card-shadow {
                display: none;
              }

              .product-overview-bg {
                &.active {
                  display: block;
                  // width: rem(305px);
                  // height: rem(382px);
                  // margin: -3rem auto 0 auto;
                  width: 100%;
                  height: rem(299px);
                  margin: 0 auto;

                  &.merdeka {
                    width: 100%;
                    height: auto;
                    margin: -2rem auto 0 auto;
                  }

                  &.halloween {
                    transform: scale(1.2);
                  }
                  &.anni{
                    transform: scale(1.5);
                  }
                }
              }
            }
          }
        }

        .deco-1,
        .deco-2 {
          position: absolute;
          bottom: -4%;
        }

        .deco-1 {
          width: rem(182px);
          height: auto;
          left: 24%;

          &.halloween {
            width: 4.5rem;
            left: 29%;
            bottom: -1%;
          }
        }

        .deco-2 {
          width: rem(200px);
          height: auto;
          right: 24%;

          &.halloween {
            width: 9rem;
            right: 29%;
            bottom: -5%;

          }
        }
      }
    }
  }
}

.service-section {
  overflow-x: hidden;

  &.merdeka {
    background-image: url("../../../../images/home/merdeka/services-bg.png");
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &.mooncake {
    background-image: url("../../../../images/home/mooncake/services-bg.webp");
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &.halloween {
    background-image: url("../../../../images/home/halloween/services-bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .halloween-deco-3 {
      position: absolute;
      width: 35%;
      top: 7%;
      left: -13%;
      transform: scaleX(-1);
      z-index: 1;
    }
  }

  &.raya {
    .service-section-content {
      background-image: url("../../../../images/home/raya/services-bg.png");
      padding-top: 3rem;
    }

    .border-deco {
      width: 98%;
      height: 103%;
      top: -2%;
      right: 2%;
    }

    .service-deco-1,
    .service-deco-2 {
      height: auto;
      position: absolute;
      z-index: 1;
    }

    .service-deco-1 {
      width: rem(416px);
      bottom: -5%;
      left: -22%;
    }

    .service-deco-2 {
      width: rem(148px);
      bottom: 0;
      right: -32%;
    }
  }

  &.cny {
    .service-section-content {
      background-image: url("../../../../images/home/chinese/border-bg-sm.png");
    }

  }
}

.section-header {
  &.cny-header {
    h2 {
      display: flex;
      align-items: center;
      color: white;
      background-image: url("../../../../images/home/chinese/title-deco-bg.webp");
      background-size: 100% 100%;
    }
  }
}

.raya-bg-deco {
  background-image: url("../../../../images/home/raya/raya-bg.png");
  background-repeat: repeat-y;
  background-position: top;
  background-size: contain;

  .events-section {
    .x-container {
      margin-top: 0;
      padding-top: 5rem;
    }
  }

  .service-section {
    .x-container {
      margin-bottom: 0;
      padding-bottom: 5rem;
    }
  }
}

.border-deco {
  position: absolute;
  pointer-events: none;
}

// .banner-slick {
//   .banner-deco {
//     position: relative;
//     width: 100%;
//     max-width: 1980px;
//     margin: 0 auto;
//     .cny-deco-1,
//     .cny-deco-2 {
//       position: absolute;
//       top: 0;
//       width: 100%;
//       max-width: rem(198px);
//       height: auto;
//       z-index: 2;
//     }
//     .cny-deco-1 {
//       left: -2%;
//     }
//     .cny-deco-2 {
//       right: -2%;
//       transform: rotateY(180deg);
//     }
//   }
// }

.mobile-view {
  .banner-slick {

    .cny-deco-1,
    .cny-deco-2 {
      max-width: rem(122px);
    }

    .cny-deco-1 {
      left: -6%;
    }

    .cny-deco-2 {
      right: -6%;
    }
  }
}

//merdeka video
.merdeka-video {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: -999;
  object-fit: cover;
}

//mid autumn
.event-top-deco {
  .top-deco-one {
    margin: 0rem 2rem;
    position: fixed;
    z-index: 99;
  }

  .top-deco-two {
    transform: scaleX(-1);
    right: 3%;
    position: fixed;
    z-index: 99;
  }
}

.bottom-nav-section {
  &.mooncake {
    .bottom-nav-content {
      .home-btn {
        .bottom-icon {
          box-shadow: none !important;
        }

        .bottom-icon-active {
          box-shadow: none !important;
          width: 135% !important;
          height: auto !important;
          ;
          margin-left: -1.25rem;
          padding-bottom: 0.5rem;
        }
      }

      .nav-link {
        img {
          height: 2.25rem;
          width: auto;
        }
      }

      .nav-link.active {
        .bottom-icon-active {
          height: 3rem;
          width: auto;
          margin-top: -0.5rem;
          margin-bottom: 0rem;
        }
      }

    }

  }

  &.cny {
    background-image: url("../../../../images/home/chinese/cny-mobile-bg.webp");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .bottom-nav-content {
      background-color: unset;

      .home-btn {
        .bottom-icon {
          box-shadow: none !important;
        }

        .bottom-icon-active {
          width: 100% !important;
          box-shadow: none !important;
          height: auto !important;
          padding-bottom: 0.5rem;
          transform: scale(1.4);
        }
      }

      .nav-link {
        img {
          height: 2.25rem;
          width: auto;
        }
      }

      .nav-link.active {
        .bottom-icon-active {
          height: 3rem;
          width: auto;
          margin-top: -0.5rem;
          margin-bottom: 0rem;
        }
      }

    }

  }

  &.christmas {
    .nav-link {
      img {
        height: 2.25rem;
        width: auto;
      }
    }
  }

  &.raya {
    .bottom-nav-content {
      background-image: url(../../../../images/home/raya/bottom-nav-bg.webp);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .nav-link {

      img {
        height: 2.25rem;
        width: auto;
      }
    }
  }
  &.uefa {

      .home-btn {
        .bottom-icon {
          box-shadow: none !important;
        }

        .bottom-icon-active {
          width: 100% !important;
          box-shadow: none !important;
          height: auto !important;
          padding-bottom: 0.5rem;
          transform: scale(1.4);
        }

    }

  }
}