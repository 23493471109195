.app-section {
  .global-section {
    .section-image-container {
      z-index: 1;
      right: -1%;
      left: unset;

      .app-img {
        width: 100%;
        max-width: rem(560px);
        height: auto;
      }
    }

    .section-content-container {
      justify-content: flex-start;
      margin-left: 0;
      position: relative;

      .section-content {
        position: unset;
        height: auto;
        width: 100%;
        max-width: rem(720px);
        text-align: center;
        padding: 3rem;

        p {
          padding-right: 0;
        }

        .qr-code-container {

          // display: flex;
          // justify-content: space-around;
          // align-items: center;
          .qr-code {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: rem(180px);
            border-radius: 5%;
            background-color: white;
            box-shadow: $slate-gray-card-shadow;
          }
        
          // .qr-type {
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   margin-top: 1rem;

          //   img {
          //     width: 30%;
          //     max-width: rem(32px);
          //     height: rem(38px);
          //   }

          //   p {
          //     font-size: $text-12;
          //     width: 70%;
          //     padding: 0;
          //   }
          // }

          // .qr-code-col {
          //   display: flex;
          //   flex-direction: column;
          //   align-items: center;
          //   width: 100%;
          //   max-width: rem(260px);
          // }
        }

        .btn-gradient-blue {
          width: rem(150px);
          height: rem(50px);
        }
      }
      .btn-white{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../images/game-white.png");
        background-size: 100% 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        border-radius: 2.75rem;
      }
      .btn-white,
      .enter-btn-blue {
        width: 10rem;
        height: 3.25rem;
        font-size: $text-14;
        margin: 0 0.5rem;
        > div {
          margin-top: rem(-2px);
          text-align: left;
          small {
            font-size: $text-10 ;
          }
          span {
            display: block;
          }
        }
        img {
          width: 100%;
          max-width: rem(24px);
          height: auto;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .guide-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-image: url("../../../images/download-app/download-guide-bg.webp");
    background-size: 100% 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 3rem 1rem;
    text-align: center;
    position: relative;

    .guide-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2,
      h4 {
        font-weight: $font-semibold;
        text-shadow: rgba($blue-500, 0.2) 0 3px 6px;
      }

      h4 {
        color: $blue-500;
      }

      .guide-step {
        font-size: $text-18;
        font-weight: $font-semibold;
        color: $slate-gray-800;
      }

      .guide-desc {
        font-size: $text-14;
      }
      .btn-white > img {
        width:auto;
      }
    }

    .slick-container {
      width: 50%;
      max-width: rem(235px);

      .guide-image {
        width: 100%;
        max-width: rem(220px);
        height: rem(460px);
      }

      .slick-slider {
        position: unset;

        .slick-slide {
          img {
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transform: scale(0.8);
            opacity: 0.5;
          }

          &.slick-current {
            img {
              transform: scale(1);
              opacity: 1;
            }
          }
        }

        .slick-dots {
          left: 50%;
          bottom: 10%;
          transform: translateX(-50%);
        }
      }

      // .slick-dots {
      //   left: 120%;
      //   bottom: 0;
      // }
      .slick-arrow {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        .slick-arrow-btn {
          cursor: pointer;
          pointer-events: visible;
          opacity: 0.65;
          background-color: $slate-gray-500;
          transition: all 0.3s ease-in-out;
          width: rem(35px);
          height: rem(70px);

          &:hover {
            background-color: $blue-500;
            opacity: 100;
          }

          svg {
            color: $slate-gray-100;
            margin: auto;
            display: block;
            height: 100%;
            width: 25px;
          }

          &.next {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.prev {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
        }
      }
    }
  }
}

.mobile-view {
  .app-section {
    position: relative;

    .sb-logo {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 3.75rem;
      height: auto;
    }

    h1 {
      font-weight: $font-semibold;
      text-shadow: rgba($blue-500, 0.2) 0 3px 6px;
    }

    .download-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;

      .app-img {
        width: 100%;
        height: auto;
        padding: 1rem;
      }

      .download-content {
        text-align: center;
        margin-top: 1rem;

        h1 {
          color: $blue-500;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .btn-white {
          height: 2.5rem;
          border-radius: 0.25rem;
          margin-top: 0.5rem;
          color: $blue-500;
          font-weight: $font-medium;
        }
      }
    }

    .guide-container {
      padding: 2rem 1rem;
      margin-bottom: 3rem;
      text-align: center;
      overflow: unset;
      position: unset;

      h1 {
        font-weight: $font-semibold;
      }

      .guide-image {
        margin-bottom: 1rem;
      }

      .slick-container {
        .slick-slider {
          .slick-dots {
            bottom: 2rem;
          }
        }
      }
    }
  }
}

.app-install-wrapper{ 
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: .5rem 1rem;
  box-shadow: $slate-gray-card-shadow;
  .app-install-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: rem(50px);
      height: auto;
      margin-right: .5rem;
      border-radius: rem(10px);
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-500;
    color: $white;
    border-radius: rem(10px);
    padding: rem(7.5px) rem(15px);
    font-size: $text-14;
    font-weight: $font-semibold;
    line-height: 1;
    outline: none;
    border: none;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .app-info {
    .app-name {
      font-size: $text-14;
      font-weight: $font-semibold;
      line-height: 1.2;
      color: $blue-500;
    }
    .app-desc {
      font-size: $text-12;
      font-weight: $font-medium;
      line-height: 1.4;
    }
  }
}