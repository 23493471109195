.regionLanguage-section {
  .select-language{
    display: flex;
    align-items: center;
    background: white;
    padding: .5rem 1rem;
    .btn-outline-blue {
      display: flex;
      align-items: center;
      height: 2rem;
      border-radius: 4rem;
      margin-left: 1rem;
      .btn {
        width: 4rem;
        text-align: end;
        margin: 0 auto;
        &:focus {
          box-shadow: unset;
        }
      }
      svg {
        width: 2rem;
        margin: 0 auto;
        font-size: $text-20;
      }
    }
  }
  .select-region {
    background: white;
    padding: 1rem;
    ul {
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        height: rem(60px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        border: 1px solid $slate-gray-300;
        border-radius: rem(10px);
        color: $slate-gray-800;
        margin-bottom: 1rem;
        .flags-img {
          width: rem(30px);
          height: rem(30px);
          margin-right: 1rem;
        }
        .checked {
          font-size: $text-20;
          color: $blue-500;
          display: none;
        }
        .uncheck {
          font-size: $text-20;
          color: $slate-gray-200;
        }
        &.active {
          border-color: $blue-500;
          .uncheck {
            display: none;
          }
          .checked {
            display: block;
          }
        }
        &:last-child{
          margin: 0;
        }
      }
    }
  }
}