.info-center-section {
  .top-nav {
    border-bottom: 1px solid rgba(93, 122, 207, 0.4);
    .nav-link {
      &.active {
        border-bottom: 3px solid;
        border-radius: unset;
        background-color: unset;
        color: $blue-600;
        p {
          color: $blue-600;
        }
      }
      p {
        font-weight: 600;
        color: $slate-gray-800;
      }
    }
  }

  .sidebar-nav {
    .nav {
      background-color: #fff;
      border-radius: 20px;
      padding: 2rem 0;
      .nav-link {
        padding: 1rem;
        border-radius: unset;
        color: $slate-gray-800;
        &.active {
          background-color: $blue-100;
          border-left: 3px solid;
          border-color: $blue-500;
          color: $blue-500;
        }
        &:hover {
          color: $blue-500;
        }
      }
    }
    .card-shadow {
      display: flex;
    }
    &.mobile{
      padding: 1rem;
      .nav-link{
        border-radius: unset;
        border-bottom: 1px solid rgba($blue-400,0.5);
        padding: 1rem 0rem;
        &.active {
          border-color: unset!important;
          color: unset!important;
        }
        &:last-child{
          border-bottom: unset;
        }
      }
    }
  }

  .info-content {
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem;
  }

  .tab-content {
    .accordion {
      .accordion-item {
        border: unset;
        margin-bottom: 0.75rem;
        .accordion-button {
          h6 {
            font-weight: 600;
            margin-bottom: unset;
          }
          &.collapsed {
            border: 1px solid rgba(93, 122, 207, 0.4);
            background-color: unset;
            border-radius: 5px;
            color: $slate-gray-800;
          }
          &:not(.collapsed) {
            background-color: $blue-100;
            box-shadow: unset;
            border-radius: rem(10px);
          }
        }
        .accordion-collapse {
          .accordion-body {
            .li-alphabet{
              list-style-type: lower-alpha;
            }
            .li-roman{
              list-style-type: lower-roman;
            }
            img{
              width: 100%;
              height: auto;
              padding-bottom: 1rem;
            }
            h6 {
              font-weight: 700;
              margin-bottom: 0rem !important;
            }
            p {
              margin-bottom: 1rem !important;
            }
            ol {
              padding-left: 1rem;
            }
            ul {
              padding-left: 4.2rem;
            }
            .custom-list {
              list-style-type: none;
              padding-left: 3.2rem;
              margin-bottom: 0px;
              label {
                float: left;
                margin: 0 0 0 rem(-51px);
              }
            }
            &.info-cont-w-count {
              .custom-list-count {
                counter-reset: item;
                padding-left: 0.8rem;
                margin-bottom: 0rem;
                li {
                  display: block;
                  &::before {
                    content: counters(item, ".") ". ";
                    counter-increment: item;
                    margin-left: rem(-12px);
                  }
                }
              }
              .custom-list-count-2 {
                li {
                  padding-left: 2rem!important;
                  &::before {
                    margin-left: rem(-25px)!important;
                  }
                }
              }
            }
            .btn-outline-blue{
              display: flex;
              justify-content: center;
              align-items: center;
              width: rem(400px);
              margin: 0 auto;
            }
          }
        }
      }
    }
    .info-tnc {
      .tnc-custom-list {
        padding-left: 1rem;
        list-style-type: none;
        margin-bottom: 0px;
        li {
          padding-bottom: 1rem;
          padding-left: 0.5rem;
        }
        label {
          float: left;
          margin: 0 0 0 -1.5rem;
        }
      }
    }
  }


//mobile info centre
&.ic-m-section {
  .tab-content-container {
    background: white;
    margin-top: 1.5rem;
    padding: 1rem;
    box-shadow: $slate-gray-card-shadow;
    .tab-content {
      .accordion {
        .accordion-item {
          border-radius: rem(10px);
          .accordion-header {
            .accordion-button {
              h6 {
                font-weight: $font-regular;
              }
              &.collapsed {
                border-radius: rem(10px);
              }
              &:not(.collapsed) {
                border: rem(1px) solid $blue-500;
                color: $blue-500;
              }
            }
          }
          .accordion-body {
            ul {
              padding-left: 2rem;
            }
            img {
              width: 100%;
              height: auto;
              padding-bottom: 1rem;
            }
            .btn-gradient-blue {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: rem(50px);
              border-radius: rem(10px);
              color: #fff;
            }
          }
        }
      }
    }
  }

  .faq-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: $slate-gray-card-shadow;
    svg {
      font-size: $text-24;
    }
  }
 }
}