// Swal //
.swal2-popup {
  max-width: 400px;
  border-radius: 15px;
  background-image: url("../../../images/modal-bg.jpg");
}
.swal2-icon {
  font-size: 10px !important;
}
.swal2-title {
  font-size: $text-16 !important;
  margin-bottom: 7px !important;
  color: $slate-gray-700;
}
.swal2-actions.swal2-loading {
  margin-top: 0 !important;
}
.swal2-content {
  font-size: $text-14 !important;
  color: $slate-gray-500;
}
.swal2-actions {
  margin-top: 7px;
  button {
    font-size: $text-14 !important;
    transition: 0.3s ease-in-out !important;
  }
  .swal2-styled {
    border-radius: 0.25rem;
    padding: 0.625em 2em;
  }
  .swal2-confirm {
    background: linear-gradient(to right, $blue-400, $blue-600) !important;
    box-shadow: $blue-shadow;
    &:hover {
      box-shadow: 0 0 0 0.25rem rgba($blue-500, 0.25) !important;
    }
  }
  .swal2-deny,
  .swal2-cancel {
    color: $blue-500 !important;
    background: rgba(255, 255, 255, 0) !important;
    border: 1px solid $blue-500 !important;
    box-shadow: 0 3px 5px rgba($gray-600, 0.25) !important;
    &:hover {
      box-shadow: $blue-hover-shadow !important;
    }
  }
}

//Swal mobile
.swal-mobile-container {
  .swal2-popup {
    background-image: unset;
    max-width: 100%;
    width: 80%;
    padding: 1.25rem 0rem 0rem 0rem;
    .swal2-icon {
      width: 3.5rem;
      height: 3.5rem;
      margin-top: -3rem !important;
    }
    .swal2-content {
      padding: 0.3rem 1.6rem 0.5rem 1.6rem;
    }
    .swal2-actions {
      border-top: solid 0.1rem rgba($blue-400, 0.5);
      .swal2-confirm {
        background: unset !important;
        box-shadow: unset;
        color: $blue-600;
        font-size: $text-16 !important;
        padding: 0.5rem;
        margin: unset;
        width: 100%;
      }
    }
  }
  &.sucess {
    .swal-success-icon {
      background-color: $green-500;
      border: solid white 0.4rem;
      img {
        width: rem(40px);
      }
    }
  }
  &.failed,
  &.reminder,
  &.confirmation {
    .swal-failed-icon {
      background-color: $red-500;
      border: solid white 0.4rem;
      img {
        width: rem(40px);
      }
    }
    .swal-reminder-icon {
      background-color: $orange-500;
      border: solid white 0.4rem;
      img {
        width: rem(40px);
      }
    }
    .swal-confirmation-icon {
      background-color: $blue-500;
      border: solid white 0.4rem;
      img {
        width: 2rem;
      }
    }
    .swal2-actions {
      .swal2-deny,
      .swal2-cancel {
        background: rgba(white, 0.3) !important;
        color: white !important;
        border: unset !important;
        width: 100%;
        margin: unset;
        font-size: $text-16 !important;
        margin-bottom: -3.5rem;
        border-radius: 0.5rem;
      }
      .swal2-confirm {
        background: unset !important;
        box-shadow: unset;
        color: $blue-600;
        font-size: $text-16 !important;
        padding-bottom: 0.5rem;
        margin: unset;
        width: 100%;
      }
    }
    .swal-sorry-icon {
      background-color: white;
      border: solid white 0.4rem;
      img {
        width: 3.5rem;
      }
    }
  }
  &.sorry {
    .swal-sorry-icon {
      background-color: white;
      border: solid white 0.4rem;
      img {
        width: 3.5rem;
      }
    }
  }
}
