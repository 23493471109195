.right-fixed-button {
  position: fixed;
  min-height: 40%;
  max-width: rem(80px);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  bottom: 0;
  right: 0;
  // transform: translateY(-50%);
  pointer-events: none;
  .btn-white,
  .back-to-top {
    display: block;
    pointer-events: auto;
    height: rem(80px);
    width: rem(80px);
    padding: 0.75rem;
    margin-left: auto;
    margin-bottom: 1rem;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    p {
      font-size: $text-12;
    }
    img {
      height: rem(40px);
      width: rem(40px);
      object-fit: contain;
      margin: 0 auto;
      margin-bottom: 0.5rem;
    }
    svg {
      height: rem(35px);
      width: rem(35px);
      padding: 0.25rem;
      color: white;
      transition: all 0.3s ease-in-out;
      background: linear-gradient(to right, $blue-400, $blue-600);
      border-radius: 50%;
    }
  }
  .back-to-top {
    margin-top: auto;
    &:focus,
    &:hover {
      outline: 0;
      box-shadow: none;
      svg {
        box-shadow: $slate-gray-hover-shadow;
      }
    }
  }

  .gif-btn-container {
    position: absolute;
    bottom: 15%;
    right: 5.5rem;
    width: 12rem;
    height: 12rem;
    .gif-btn {
      position: relative;
      display: block;
      pointer-events: auto;
      &:focus-visible{
        outline: none;
      }
      a, .gif-img {
        display: block;
        &:focus-visible{
          outline: none;
        }
        img,
        video {
          display: block;
          cursor: pointer;
          width: 12rem;
          height: 12rem;
          object-fit: contain;
          object-position: center;
          transition: all 0.3s ease-in-out;
          &:focus-visible{
            outline: none;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .btn {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      pointer-events: all;
      padding: 0;
      svg,img {
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    .slick-dots {
      bottom: -0.5rem;
      pointer-events: all;
      li {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0 0.25rem;
        button {
          background-color: $slate-gray-400;
          width: 0.5rem;
          height: 0.5rem;
          margin: 0;
          padding: 0;
          &::before {
            width: 0;
            height: 0;
          }
        }
        &.slick-active {
          button {
            background-color: $blue-500;
          }
        }
      }
    }
  }
}

.vip-link-modal {
  max-width: rem(500px) !important;
  .btn-close {
    background: transparent
      url("https://api.iconify.design/mdi:window-close.svg?color=%23ffffff")
      center/1.5em auto no-repeat !important;
    &.btn-close-bg {
      background-color: $gray-400 !important;
      border-radius: 99px;
      padding: .5rem;
    }
  }
  .modal-header {
    background-color: $blue-600;
    margin-top: -1px;
    padding: 0.5rem 1rem;
    h5 {
      color: white !important;
    }
    &.modal-header-bg-transparent {
      background-color: transparent !important;
    }
  }
  .modal-content {
    border: unset;
    .modal-header {
      border: none;
      border-top-left-radius: rem(15px);
      border-top-right-radius: rem(15px);
    }
    .modal-footer {
      flex-direction: column;
      border: none;
      padding: unset;
      input {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    .modal-body {
      padding: 0;
      display: block !important;
      width: 100%;
      h6 {
        font-weight: 600;
      }
      h5 {
        color: black;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
        height: auto;
      }
      .btn-gradient-blue {
        width: max-content;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        margin: 0 auto;
      }
      .btn-outline-blue {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: unset;
        &.active {
          color: white;
          background-color: $blue-500;
        }
      }
      ul {
        padding: 0rem;
        li {
          width: 100%;
          svg {
            font-size: $text-18;
          }
        }
      }
    }
  }
  .slick-slide{
    padding: 0 1rem;
  }
  .slick-dots {
    bottom: -5rem;
  }
}


.mobile-view {
  .global-modal {
    .vip-link-modal {
      max-width: 80% !important;
      margin: 0 auto;
    }
  }
  .right-fixed-button {
    top: unset;
    bottom: 10%;
    min-height: unset;
    max-width: unset;
    .gif-btn-container {
      width: 8rem;
      height: 8rem;
      right: 1rem;
      bottom: 0;
      .gif-btn {
        a, .gif-img {
          img,
          video {
            width: 8rem;
            height: 8rem;
          }
        }
      }
    }
  }
}

