.gradient-bg-banner {
  &::after {
    content: "";
    position: absolute;
    // bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 30%;
    width: rem(1920px);
    background: linear-gradient(to top,
        rgba(255, 255, 255, 0),
        $slate-gray-100 );
    pointer-events: none;
    z-index: -1;
  }
}

// Desktop Styling //
.banner-slick {
  // width: rem(1920px);
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 0 auto;

  .slick-container {
    position: relative;
    height: rem(800px);

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   height: 20%;
    //   width: rem(1920px);
    //   background: linear-gradient(to bottom,
    //       rgba(255, 255, 255, 0),
    //       $slate-gray-100 );
    //   pointer-events: none;

    // }

    .slick-slider {
      // position: relative;
      // height: unset !important;
      overflow: hidden;
      touch-action: auto !important;

      position: absolute;
      width: rem(1920px);
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .slick-slide {
        line-height: 0;

        img,
        svg {
          width: 100%;
          // height: rem(800px);
          // object-fit: cover;
          // object-position: center;
          transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
          transform: scale(1.2);
        }

        &.slick-current {
          position: relative;
          z-index: 5;

          img {
            transform: scale(1);
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      pointer-events: none;

      .slick-arrow-btn {
        cursor: pointer;
        pointer-events: visible;
        opacity: 0.65;
        background-color: $slate-gray-500;
        transition: all 0.3s ease-in-out;
        width: 35px;
        height: 70px;

        @include max-lg {
          width: 25px;
          height: 50px;
        }

        &:hover {
          background-color: $blue-500;
          opacity: 100;
        }

        svg {
          color: $slate-gray-100;
          margin: auto;
          display: block;
          height: 100%;
          width: 25px;
        }
      }
    }
  }

  &.uefa {
    .slick-container {
      height: rem(250px);

      .slick-slider {
        width: 92%;

      }
    }

  }
}

.banner-col {
  position: relative;
  height: rem(800px);
  overflow: hidden;

  .banner-item {
    position: absolute;
    width: rem(1920px);
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mobile-view {
  .banner-slick {
    // margin-top: rem(-60px);
    padding: 0rem 1rem;
    width: 100%;
    overflow: unset;

    .slick-container {
      position: relative;
      height: 10rem;

      &::after {
        display: none;
      }

      .slick-slider {
        position: relative;
        height: unset;
        width: 100%;
        left: unset;
        transform: unset;
        border-radius: 0.5rem;

        .slick-slide img {
          width: 100%;
          height: 10rem;
          object-fit: cover;
          object-position: center;
        }
      }

      .slick-dots li button {
        width: rem(16px);
        height: rem(16px);

        &::before {
          width: rem(8px);
          height: rem(8px);
        }
      }
    }
  }

  .banner-col {
    height: rem(250px);

    .banner-item {
      width: 100%;

      img {
        height: rem(250px);
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.slick-dots {
  z-index: 10;
  bottom: 25px;

  li {
    margin: 0 0.5rem;

    &.slick-active {
      button {
        border: 1px solid $blue-600;

        &::before {
          background-color: $blue-600;
          color: $blue-600;
        }
      }
    }

    button {
      position: relative;
      border-radius: 50%;
      background-color: white;
      box-shadow: $slate-gray-shadow;
      width: rem(20px);
      height: rem(20px);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: rem(12px);
        height: rem(12px);

        color: transparent;
        background-color: transparent;
        border-radius: 50%;
        opacity: 1;
      }
    }
  }
}

.banner-slick.resp {
  @include max-lg {
    .slick-slide {

      img,
      svg {
        min-height: 180px;
        object-fit: cover;
      }
    }
  }
}

//select vip card
.vip-slick,
.vip-m-slick {
  .slick-container {
    position: relative;

    .slick-track {
      padding: 3rem 0;

      .slick-slide {
        transition: all 0.3s ease-in-out;
        padding: 0 0.75rem;

        img {
          width: 100%;
          height: auto;
        }

        &:not(.slick-center) {
          opacity: 0.5;
        }

        &.slick-current {
          @include shineEffect;
        }
      }

      .slick-center {
        position: relative;
        z-index: 10;
        opacity: 1;
        transform: scale(1.35);
      }
    }

    .slick-dots {
      width: rem(800px);
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);

      li {
        position: relative;
        width: 30px;
        height: 30px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          height: 0.25rem;
          width: 4rem;
          z-index: -1;
          transform: translateY(-50%);
          background-color: white;
        }

        &:last-child {
          &::after {
            content: "";
            width: 0;
            height: 0;
          }
        }

        span {
          height: 100%;
          width: 100%;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .slick-active span {
        padding: 0;
        background-color: $blue-500;
        color: white;
        box-shadow: $blue-hover-shadow;
      }

      &::before {
        content: "";
        background-image: url("../../../images/vip/crown-blue.webp");
        padding: rem(28px);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;

    .slick-arrow-btn {
      cursor: pointer;
      pointer-events: visible;
      opacity: 0.65;
      background-color: $slate-gray-500;
      transition: all 0.3s ease-in-out;
      width: 55px;
      height: 120px;

      &.next {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.prev {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      @include max-lg {
        width: 25px;
        height: 50px;
      }

      &:hover {
        background-color: $blue-500;
        opacity: 100;
      }

      svg {
        color: $slate-gray-100;
        margin: auto;
        display: block;
        height: 100%;
        width: 60px;
      }
    }
  }
}

//mobile slick

.vip-m-slick {
  padding: 0 1rem;
  margin-top: rem(90px);
  background: white;

  .slick-container {
    .slick-track {
      padding: 1rem 0;

      .slick-slide {
        &:not(.slick-center) {
          opacity: unset;
        }
      }
    }

    .slick-dots {
      height: 5rem;
      width: 80%;
      justify-content: center;
      flex-wrap: wrap;
      bottom: 102%;
      left: unset;
      right: -.5rem;
      transform: unset;

      li {
        width: rem(30px);
        height: rem(30px);
        margin: 0 .65rem;

        &::after {
          width: 1.75rem;
          right: -80%;
        }

        &:nth-child(6n) {
          &::after {
            content: "";
            width: 0;
          }
        }
      }

      &::before {
        background-image: none;
        padding: 0;
      }
    }

    .crown-blue {
      position: absolute;
      top: -35%;
      width: 5.5rem;
      height: auto;
    }
  }
}

.mobile-game-container {
  width: calc(100% - 4.25rem);
  overflow-y: auto;
  grid-gap: 0.5rem;
  gap: 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  position: relative;

  .mobile-game-card {
    position: relative;

    // padding: 0rem 0rem 1rem 0rem;
    &.hot,
    &.hot-cn {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: rem(45px);
        pointer-events: none;
      }
    }

    &.hot {
      &::after {
        background: url("../../../images/hot-m.gif");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    &.hot-cn {
      &::after {
        background: url("../../../images/hot-cn-m.gif");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    .btn-white {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
    }

    .fav-btn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      border: none;
      background: none;

      img {
        width: 1.75rem;
      }
    }
  }

  .card-shadow {
    border-radius: 0.75rem;
    // img {
    //   height: 100%;
    // }
  }

  .slick-arrow {
    position: absolute;
    top: -1.5rem;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    align-items: center;
    padding: 1rem;

    .slick-arrow-btn {
      cursor: pointer;
      pointer-events: visible;
      opacity: 0.7;
      background-color: $blue-500;
      transition: all 0.3s ease-in-out;
      width: 2rem;
      padding: 0rem;
      height: 2rem;

      &:disabled {
        opacity: 0.2;
      }

      &.next {
        border-radius: 0.3rem;
      }

      &.prev {
        border-radius: 0.3rem;
      }

      svg {
        color: $slate-gray-100;
        margin: 0 auto;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .slick-dots {
    top: -2.5rem;
    z-index: 0;
    bottom: unset;

    li {
      width: 3rem;
      height: 1rem;

      button {
        width: 3rem;
        height: 0.75rem;
        border-radius: 0.5rem;

        &::before {
          width: 2.5rem;
          height: 0.3rem;
          border-radius: 1rem;
        }
      }
    }

    ul {
      padding: 0rem;
    }
  }
}

//home page product overview

.product-overview-slick {
  .slick-container {
    &.christmas {
      .slick-track {
        padding: 6rem 0 6rem 0;
      }
    }

    .slick-track {
      padding: 3rem 0 6rem 0;

      .product-overview {
        position: relative;
        display: flex;
        align-items: center;

        &:focus-visible {
          outline-width: 0;
        }

        .product-overview-img {
          width: 100%;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.25s ease-in-out;

          &:focus-visible {
            outline-width: 0;
          }
        }

        .card-shadow {
          width: rem(284px);
          height: rem(299px);
          margin: 0 auto;
        }
      }

      //if is center slick
      .slick-center {
        transform: scale(1);

        .product-overview {
          .product-overview-img {
            img {
              transform: scale(1.25);
            }
          }
        }
      }
    }

    .product-platform,
    .platform-wave {
      position: absolute;
      bottom: 0;
      // left: 50%;
      // transform: translateX(-50%);
      z-index: -1;

      &.christmas {
        bottom: -3rem !important;
        z-index: unset;
      }
    }

    .platform-wave {
      bottom: -3rem;
      animation: zonIn 3s infinite;
      z-index: -2;
      opacity: 0;
    }

    .platform-deco {
      position: absolute;
      transform: translate(50%, -50%);
      left: 15%;
      bottom: -35%;
    }
  }
}