.section-header,
.section-header-gray {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;

  h2,
  h3 {
    margin-bottom: 0;
    color: $blue-500;
    font-size: $text-36;
    font-weight: $font-semibold;
    text-shadow: rgba($blue-500, 0.2) 0 3px 6px;
    text-transform: uppercase;
  }

  p {
    font-size: $text-18;
    text-align: center;
    padding: 0 6rem;
  }
}

.section-header-gray {

  h2,
  h3 {
    color: $slate-gray-500;
  }

  p {
    color: $slate-gray-300;
  }
}

.mobile-view {
  .section-header-gray {
    h2 {
      font-size: $text-24;
    }

    p {
      font-size: $text-16;
      padding: 0;
    }

    .event-date {
      width: 100%;
      font-size: $text-12 !important;
    }
  }
}

.section-m-header {
  margin-bottom: 1rem !important;

  // h3 {
  //   font-size: $text-20 !important;
  // }
}

.global-section {
  .section-image-container {
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 1;

    .section-img {
      height: auto;
      width: 100%;
      max-width: 550px;
    }
  }

  .section-content-container {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    width: rem(887px);
    height: rem(480px);
    background-image: url("../../../images/home/wwr-bg.webp");
    background-repeat: no-repeat;
    background-position: bottom;
    margin-left: auto;
    padding: 1rem;

    .section-content {
      width: 100%;
      max-width: 70%;
      height: 74%;
      &.cny,
      &.christmas{
        max-width: 58%;
      }

      h2,
      h1 {
        color: $blue-500;
        font-weight: $font-semibold;
        text-shadow: rgba($blue-500, 0.2) 0 3px 6px;
        white-space: break-spaces;
        margin-bottom: 1rem;
      }

      p {
        max-height: 85%;
        color: $slate-gray-500;
        overflow: auto;
        padding-bottom: 1rem;
        padding-right: 1rem;
      }

      ::-webkit-scrollbar {
        width: 0.25rem;
      }
    }
  }

  &.event {
    .section-image-container {
      left: -16%;

      .section-img {
        max-width: rem(780px);
      }
    }
  }
}

.global-section-two {
  .card-shadow {
    width: 100%;
    max-width: 789px;
  }

  .section-two-img {
    position: absolute;
    top: 4%;
    right: 0%;
    width: 100%;
    max-width: 600px;
    height: auto;
  }

  .section-two-content {
    position: absolute;
    top: 50%;
    left: 0%;
    padding: 1.75rem;
    width: 50%;
    height: auto;
    text-align: center;
    transform: translateY(-50%);

    h1,
    h2 {
      color: $blue-500;
      font-weight: $font-semibold;
    }

    p {
      width: 100%;
      height: 230px;
      padding: 0 1rem 1rem 0;
      overflow: auto;
    }
  }
}

.provider-games-section {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .col-5 {
    display: flex;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .col-7 {
    padding-top: 5rem;
  }

  &>.x-container-no-m>.row {
    min-height: 980px;
    padding-left: inherit;
    padding-right: inherit;
  }
}

.pd-card-col,
.pd-card-sub {
  padding: 1.25rem;
  border-radius: 1.25rem;
  background: white;
  box-shadow: $slate-gray-card-shadow;
}

.pd-card-col {

  h4,
  h6 {
    color: $slate-gray-800;
    font-weight: $font-semibold;
  }
}

.global-game-section {
  .game-container {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    box-shadow: $slate-gray-shadow;

    .game-category {
      border-bottom: 1px solid $slate-gray-200;

      ul {
        list-style-type: none;

        .game-ctg-col {
          flex: 0 0 auto;
          width: 14.2857%;

          .game-link {
            position: relative;
            background-image: url("../../../images/game-white.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: $slate-gray-500;
            border-radius: 25px;
            display: flex;
            font-weight: $font-medium;
            font-size: $text-12;
            width: 100%;
            height: 52px;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            align-items: center;
            justify-content: center;
            padding: inherit;
            cursor: pointer;
            box-shadow: $slate-gray-shadow;
            &.hot,
            &.hot-cn {
              &::after {
                content: '';
                position: absolute;
                top: rem(-2px);
                left:50%;
                transform: translateX(-50%);
                background-repeat: no-repeat;
                background-size: 100% auto;
                height: 100%;
                width: rem(70px);
                pointer-events: none;
              }
            }
            &.hot {
              &::after {
                background: url("../../../images/hot.gif");
                background-repeat: no-repeat;
                background-size: 100% auto;
              }
            }
            &.hot-cn {
              &::after {
                background: url("../../../images/hot-cn.gif");
                background-repeat: no-repeat;
                background-size: 100% auto;
              }
            }
            &.active {
              background-image: url("../../../images/game-blue.png");
              color: white;

              .icon-grey {
                display: none;
              }

              .icon-white {
                display: block;
                animation: zonIn 0.25s;
              }
            }

            &:hover {
              box-shadow: $slate-gray-hover-shadow;
            }

            span {
              width: 40%;
              padding-right: 5px;

              .icon-grey,
              .icon-white {
                opacity: 1 !important;
                margin: auto;
              }

              .icon-grey {
                display: block;
              }

              .icon-white {
                display: none;
              }
            }

            p {
              width: 60%;
              line-height: 1.2;
            }
          }
        }
      }
    }

    .game-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        display: flex;
        margin: 0 -15px;
        list-style-type: none;

        li {
          margin: 0 15px;
          cursor: pointer;
          height: fit-content;
          display: flex;
          align-items: center;

          &:hover,
          &.active {
            color: $blue-500;
          }

          svg {
            font-size: 1.25rem;
            margin-right: 0.25rem;
          }
        }
      }
    }

    .uefa-col{
      position: absolute;
      bottom:0;
      transform: translate(50%,-50%);
      font-size: $text-20;
      border: unset;
      background-color: unset;
      &.betnow{
        color: $blue-600 ;
        right: 30%;
        bottom: 15%;
      }
      &.playnow{
        color: white ;
        right: 27%;
        bottom: 16%;
      }
    }
  }

  // .pagination {
  //   align-items: center;
  //   margin-bottom: 0;

  //   .page-item {
  //     &.active {
  //       .page-link {
  //         background-color: transparent;
  //         color: $blue-500;
  //         font-weight: $font-semibold;
  //       }
  //     }

  //     .page-link {
  //       // border: none;
  //       font-size: $text-14;
  //       font-weight: normal;
  //       color: $slate-gray-400;
  //       border: unset;
  //       cursor: pointer;
  //       padding: 0 0.75rem;
  //       background-color: rgba(255, 255, 255, 0);

  //       &:hover {
  //         background-color: transparent;
  //         color: $blue-500;
  //         font-weight: $font-semibold;
  //       }
  //     }

  //     .page-link-arrow {
  //       // border: none;
  //       width: rem(25px);
  //       height: rem(25px);
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       background: white;
  //       font-size: $text-18;
  //       color: $slate-gray-300;
  //       box-shadow: $slate-gray-shadow;
  //       border-radius: 50%;
  //       cursor: pointer;
  //       margin: 0 rem(15px);

  //       &:hover {
  //         color: $blue-500;
  //       }
  //     }
  //   }
  // }
}

//mobile section
.global-m-section {
  position: relative;

  .section-m-img {
    width: 100%;
    min-width: rem(279px);
    height: auto;
    margin-bottom: -2rem;
  }

  .section-m-container {
    width: 100%;
    height: auto;
    background-image: url("../../../images/about-us/mobile-bg.webp");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1rem;
    border-radius: 0.75rem;
    box-shadow: $slate-gray-card-shadow;

    h1 {
      padding-top: 1.5rem;
      text-align: center;
      font-size: $text-20;
      font-weight: $font-semibold;
      color: $blue-500;
      text-transform: uppercase;
    }

    h2 {
      text-align: center;
      font-size: $text-18;
      font-weight: $font-semibold;
      color: $blue-500;
      text-transform: uppercase;
    }

    p {
      text-align: center;
    }
  }
}

//mobile provider games section
.pg-m-section {
  padding: 0 1rem;

  .search-bar {
    width: 100%;
    height: rem(40px);

    svg {
      font-size: $text-20;
      color: $blue-500;
    }

    .form-control {
      border-color: $slate-gray-200;

      &::placeholder {
        color: $slate-gray-300;
      }
    }
  }

  .game-m-nav {
    padding: 1rem 0;
    border-top: 1px solid $slate-gray-300;

    .game-nav-btn {
      justify-content: center;

      svg {
        margin-right: 0.25rem;
        font-size: $text-24;
      }

      img {
        width: rem(22px);
        height: auto;
        margin-right: 0.5rem;
      }

      .actived {
        display: none;
      }

      p {
        width: unset;
        font-size: $text-14;
        line-height: 1;
      }

      &.active {
        svg {
          color: white;
        }

        .unactived {
          display: none;
        }

        .actived {
          display: block;
        }
      }
    }
  }

  .game-list {
    .col-4 {
      position: relative;
      text-align: center;
      font-size: $text-12;

      img {
        // width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        object-position: center;
      }

      .new-tag {
        position: absolute;
        top: 0;
        left: 2%;
        background: #ffaa09;
        color: white;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 1rem;
        padding: 0 0.5rem;
      }

      .fav-icon {
        position: absolute;
        top: -4%;
        right: -2%;
        width: rem(24px);
        height: rem(22px);
      }

      .rtp {
        width: 100%;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: unset;
        bottom: 0;
        left: 0;
        color: white;
        background-color: rgba(black, 0.4);
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        span {
          padding-left: 0.25rem;
        }
      }

      .game-m-name {
        display: flex;
        margin: 0.5rem 0;
        align-items: center;
        justify-content: space-between;

        p {
          color: $slate-gray-800;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          width: rem(20px);
          height: rem(20px);
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  .modal {
    .modal-content {
      background-image: none !important;
      background: rgba(255, 255, 255, 0);

      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 12rem;
          margin: 0.5rem 0;
        }

        .btn-white {
          height: 2.5rem;
          border-radius: 0.25rem;
        }

        img {
          width: 16rem;
          height: auto;
        }

        p {
          color: white;
          font-size: $text-20;
          text-align: center;
          padding: 0.5rem 0;
        }
      }
    }
  }
}