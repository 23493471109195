.login-video {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.7);
  &.mobile {
    width: 100vw;
  }
}

.mobile-view{
  .register-section {
    .logreg-img{
      width: 100%;
      height: auto;
      border-radius: unset;
      border:unset;
    }
  }
}

.register-section {
  background-image: linear-gradient( to top, $blue-100 90%,$slate-gray-200 100%);
  .logreg-img{
    width: 100%;
    height: auto;
    border-radius: 1rem;
    border: rem(5px) solid white;
  }
  // .logo-image {
  //   max-width: 100%;
  //   width: 40%;
  //   height: auto;
  //   margin: 0 auto;
  //   display: block;
  // }
  .x-container-no-m {
    padding: 5rem 0rem;
    .col-8{
      box-shadow: $slate-gray-card-shadow;
      background: white;
      border-radius: 1rem;
      padding: 0rem;
      width: 65%;
    }
    .col-4{
      background: white;
      border-radius: 1rem;
      padding: 2rem;
      box-shadow: $slate-gray-card-shadow;
      width: 33%;
    }
    .register-form {
      // width: 300px;
      // min-height: 100vh;
      justify-content: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .pi-sec-lvl-col {
        margin-top: 0.25rem;
        small {
          font-size: 0.767em;
          color: $red-500;
        }
      }
      .checklist {
        color:$slate-gray-500;
        .form-check-input[type="checkbox"] {
          border-radius: 1rem;
        }
      }
      .register-recaptcha {
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        border-radius: rem(50px);
        // background-color: rgba(255, 255, 255, 0.2);
        background-color: $slate-gray-100;
        border: solid rem(1px) $slate-gray-400!important;
        border: unset;
        width: 100%;
        text-align: left;
        padding: 0.45rem;
        margin-top: .75rem;
        img {
          margin: 0rem 1.5rem 0rem 0.3rem;
        }
      }
      .btn-blue {
        height: rem(40px);
        border-radius: rem(50px);
        width: 100%;
        margin-top: 1rem;
        &.disabled{
          color: $slate-gray-200;
        }
      }
      .checklist,
      .small-w {
        margin-top: .75rem;
        small {
          color: $slate-gray-500;
        }
      }
      p {
        color: $slate-gray-500;
      }
      .contact-us-btn {
        img {
          width: 2rem;
          padding-right: 0.5rem;
        }
      }
      .bottom-content {
        small {
          display: block;
          .line {
            overflow: hidden;
            text-align: center;
            &:before {
              right: 0.5em;
              margin-left: -50%;
              background-color: $gray-600;
              content: "";
              display: inline-block;
              height: 1px;
              position: relative;
              vertical-align: middle;
              width: 25%;
            }
            &:after {
              background-color: $gray-600;
              content: "";
              display: inline-block;
              height: 1px;
              position: relative;
              vertical-align: middle;
              width: 25%;
              left: 0.5em;
              margin-right: -50%;
            }
          }
        }
        .google-tele-bg {
          margin-top: 1rem;
          button {
            background-color: $slate-gray-100;
            margin: 0.25rem;
            border: unset;
            border-radius: rem(10px);
            // box-shadow: inset 0rem 0rem rem(10px) rem(3px) rgba(255, 255, 255, 0.3);
            width: rem(60px);
            height: rem(60px);
            &:hover {
              box-shadow: $blue-hover-shadow;
            }
          }
        }
      }
    }
    .input-group {
      border-radius: rem(50px);
      display: flex;
      margin-top: .75rem;
      .invalid-feedback {
        color: $red-500;
        line-height: 1;
        text-align: left;
        margin-top: 0.4rem;
      }
      &:focus-within {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &::after {
        content: "";
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        height: rem(40px);
        border-radius: rem(50px);
        width: 100%;
        position: absolute;
        // z-index: -1;
        background-color: $slate-gray-100;
        border: solid rem(1px) $slate-gray-400;
        pointer-events: none;
      }
      input:-webkit-autofill {
        // -webkit-text-fill-color: #fff !important;
        transition: background-color 5000s ease-in-out 0s;
      }
      .left-icon {
        background-color: unset;
        border-radius: rem(50px) 0rem 0rem rem(50px);
        border: none;
        padding: 0.45rem !important;
        z-index: 1;
        img {
          width: 1.5rem;
        }
      }
      .input-group-text {
        border: none;
        padding: 0px;
        .right-icon {
          background-color: unset;
          border-radius: 0rem rem(50px) rem(50px) 0rem;
          border: none;
          height: 100%;
          width: rem(50px);
          z-index: 99;
          filter: invert(0.5);
          img {
            width: 1.5rem;
          }
        }
      }
      .out-icon {
        position: absolute;
        left: 100%;
        padding: 0.5rem;
      }
      .form-control {
        background-color: unset;
        border: none;
        color: $slate-gray-800;
        height: rem(40px);
        z-index: 1;
        &::placeholder {
          color: $slate-gray-200;
        }
        &:focus-within {
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
      .react-select__control .react-select__value-container{
        z-index: 1;
      }
      .react-select__control .react-select__indicator{
        z-index: 1;
      }
    }

    .recaptcha-modal {
      .modal-content {
        background-color: unset;
        border: unset;
      }
      .vertifyWrap {
        .sliderContainer {
          border-radius: rem(50px);
          background-color: rgba(255, 255, 255, 0.2);
          border: unset;
          box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
          margin-top: 0px;
        }
        .sliderContainer_success,
        .sliderContainer_fail,
        .sliderContainer_active {
          line-height: rem(35px);
          &:hover {
            line-height: rem(35px);
          }
        }
        .sliderMask {
          border: unset !important;
          background: none !important;
          background-color: none !important;
        }
        .slider {
          border-radius: rem(50px);
          background-color: rgba(255, 255, 255, 0.2);
          border: unset;
          box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        }
        .loadingContainer {
          span {
            visibility: hidden;
            position: relative;
            &::after {
              visibility: visible;
              position: absolute;
              top: 0;
              left: 0;
              content: "Loading...";
            }
          }
        }
      }
    }
    .caution-text{
        color: $red-500;
        img{
        width: 0.8rem;
        margin-right: 0.2rem;
        margin-bottom: 0.1rem;
        }
    }

    //forget-password-part
    .forget-password-wrap {
      // width: 500px;
      // min-height: 100vh;
      justify-content: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      &.mobile {
        width: 22rem;
        .forget-password-content {
          padding: 2rem;
          background-color: white;
          box-shadow: unset;
          border-radius: 1rem;
          .password-form {
            width: 100%;
          }
          h4 {
            color: $slate-gray-500;
          }
          .forget-pwd-btn {
            button {
              height: 3rem;
            }
          }
        }
        .count {
          position: unset;
          float: right;
          margin-top: 0.2rem;
          top: unset;
          margin-left: unset;
        }
        .right-btn-bg {
          .right-btn {
            padding: 0.3rem 1rem;
          }
        }
      }
      h4 {
        color: #4a89fc;
        font-weight: 600;
      }
      p {
        color: $slate-gray-500;
      }
      .forget-password-content {
        // background-color: rgba(255, 255, 255, 0.2);
        // padding: 2rem;
        // border-radius: rem(20px);
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        margin-bottom: 1rem;
        .password-form {
          width: 80%;
          height: auto;
          margin: 0 auto;
        }
        .forget-pwd-btn {
          margin-top: 1rem;
          button {
            background-color: unset;
            border: unset;
            height: 50px;
            margin: 0px 1rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      small {
        color: $slate-gray-500;
        cursor: pointer;
        &:before {
          content: "<<";
        }
      }
      .left-text {
        text-align: center;
        color: $slate-gray-500;
        padding-left: 0.8rem !important;
      }
      .btn-blue {
        height: rem(40px);
        border-radius: rem(50px);
        width: 100%;
        margin-top: 1rem;
        &.desktop{
          width: 80%;
        }
      }
      .count {
        position: absolute;
        color: $blue-400;
        margin-left: 11.4rem;
        top: 25%;
      }
      .right-btn-bg {
        padding: 0rem 0.5rem;
        border-radius: rem(50px);
      }
      .right-btn {
        background-color: $blue-600;
        border-radius: rem(50px);
        padding: 0.28rem 1rem;
        color: #fff;
        border: unset;
      }
    }

    //validated-form
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: url("../../../images/register/icon-caution.webp");
      background-repeat: no-repeat;
      margin-right: -35px;
      padding-right: 50px;
      &.no-border {
        margin-right: -84px;
        padding-right: 85px;
      }
      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.forget-pwd-desktop {
        margin-right: -137px;
        padding-right: 147px;
      }
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
      background-image: url("../../../images/register/icon-checked.webp");
      background-repeat: no-repeat;
      margin-right: -35px;
      background-size: unset;
      padding-right: 50px;
      &.no-border {
        margin-right: -84px;
        padding-right: 85px;
      }
      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.forget-pwd-desktop {
        margin-right: -137px;
        padding-right: 147px;
      }
    }
  }

  //for mobile top
  &.register-m-section {
    background-image: unset;
    background-color: $blue-100;
    .x-container-no-m {
      min-width: unset;
      padding: 2rem 0rem;
      .register-form {
        width: 20rem;
        .nav-tabs {
          border-bottom: 0;
          margin-bottom: rem(1px);
          // margin-top: 1rem;
          .nav-item {
            width: 50%;
          }
          .nav-link {
            text-align: center;
            padding: 1rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background-color: $gray-400;
            color: white;
            border: none;
            margin-bottom: rem(-1px);
            &.active {
              background-color: white;
              color: black;
              border: none;
            }
          }
        }
        .member-form {
          padding: 1.5rem;
          background-color: white;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          &.social{
            border-radius: 1rem;
            margin-top: 1rem;
          }
        }
        .bottom-content {
          .google-tele-bg {
            button {
              margin: 0.5rem;
              img {
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }
        .register-recaptcha {
          // box-shadow: inset 0rem 0rem rem(10px) rem(5px)
          //   rgba(255, 255, 255, 0.3);
          //   background-color: rgba(white, 0.15);
          img {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0rem 0.5rem 0rem 0rem;
          }
        }
      }
      // .input-group {
      //   &::after {
      //     background-color: rgba(white, 0.3);
      //     box-shadow: inset 0rem 0rem rem(10px) rem(5px)
      //       rgba(255, 255, 255, 0.8);
      //   }
      // }
      .recaptcha-modal {
        .modal-dialog {
          max-width: unset;
          .sliderContainer {
            height: rem(40px);
            background-color: rgba(255, 255, 255, 0.3);
            box-shadow: inset 0rem 0rem rem(10px) rem(5px)
              rgba(255, 255, 255, 0.5);
            .sliderMask {
              line-height: rem(38px);
              .slider {
                width: rem(40px);
                height: rem(40px);
                background-color: rgba(255, 255, 255, 0.2);
                box-shadow: inset 0rem 0rem rem(10px) rem(5px)
                  rgba(255, 255, 255, 0.3);
                  .sliderIcon{
                    font-size: 1rem;
                  }
              }
            }
          }
          .loadingIcon,
          .refreshIcon {
            width: 2rem;
            height: 2rem;
            background-size: 2rem;
          }
        }
      }
      .was-validated .form-control:invalid,
      .form-control.is-invalid {
        background-size: 1.3rem;
        margin-right: rem(-33px);
        padding-right: rem(50px);
        &.no-border {
          margin-right: rem(-83px);
          padding-right: rem(80px);
        }
        &.forget-pwd-mobile {
          margin-right: rem(-290px);
          padding-right: rem(290px);
        }
      }
      .was-validated .form-control:valid,
      .form-control.is-valid {
        background-size: 1.5rem;
        margin-right: rem(-35px);
        padding-right: rem(50px);
        &.no-border {
          margin-right: rem(-84px);
          padding-right: rem(80px);
        }
        &.forget-pwd-mobile {
          margin-right: rem(-293px);
          padding-right: rem(290px);
        }
      }
    }
  }
}

//use with forget password page select
.reg-and-log-select {
  .react-select__control {
    width: rem(115px);
    border: 0 !important;
    border-radius: rem(50px) 0rem 0rem rem(50px) !important;
    height: rem(40px);
    background-color: unset;
    &:focus-within {
      border: none !important;
      box-shadow: unset !important;
    }
    .react-select__single-value {
      p:nth-child(n + 3) {
        margin-left: rem(14px);
      }
    }
  }

  .react-select__menu {
    width: 350px;
    .react-select__option {
      p {
        color: $slate-gray-500 !important;
        margin-right: rem(14px);
      }
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
  &.mobile {
    .react-select__menu {
      width: rem(250px);
      .react-select__option {
        height: 3rem;
        padding-left: 1rem;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .react-select__single-value {
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
