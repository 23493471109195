//Withdrawal //
.bank-list-group {
  display: grid;
  gap: 0.5rem;
  > *:not(.select-bank-opts) {
    margin-right: 0;
    .form-check-label {
      min-height: rem(40px);
      max-width: unset;
      justify-content: space-between;
      &:not(.wdrawal-add-bank) {
        > * {
          flex: 1;
          &:nth-child(1) {
            text-align: left;
          }
        }
      }
      img {
        height: rem(22.5px);
      }
      & > :nth-child(1) {
        img {
          margin-right: rem(14px);
        }
      }
      & > :nth-child(2) {
        text-align: center;
      }
      & > :nth-child(3) {
        text-align: right;
      }
    }
    .wdrawal-add-bank {
      color: $blue-500;
      justify-content: center;
    }
  }
}

.wdrawal-add-bank {
  font-weight: $font-medium;
  border: 0;
  background-color: $blue-100 !important;
  color: $blue-600 !important;
  border: 0 !important;
  svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}

.select-bank-opts {
  display: grid;
  grid-template-columns: repeat(5, calc(20% - .4rem));
  gap: .5rem;
  border-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-color: $slate-gray-200;
  margin-top: unset;
  .form-check-inline {
    margin: 0;
    // margin-right: 0;
    // padding-right: 0.5rem;
    // &:nth-child(n + 5) {
    //   margin-top: 0.5rem;
    // }
    // &:nth-child(4n) {
    //   padding-right: 0;
    // }
    .form-check-label {
      font-size: $text-12;
      width: 100%;
      max-width: unset;
      flex-direction: column;
      height: 4.5rem;
      gap: .15rem;
      overflow: hidden;
      p {
        text-transform: capitalize;
      }
      img {
        height: 24px;
      }
    }
  }
}

.payment-notices-col {
  margin-top: auto;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: $blue-100;
  h6 {
    color: $blue-500;
    margin-bottom: 0.5rem;
  }
  ol {
    padding-left: 1rem;
    margin-bottom: 0;
    li {
      font-size: $text-12;
      a {
        color: $blue-500 !important;
        font-weight: $font-medium;
        cursor: pointer;
      }
      span {
        color: $blue-500;
        font-weight: $font-medium;
      }
    }
  }
}

.withdraw-crypto-amount {
  width: 100%;
  display: flex;
  .input-group {
    .form-control {
      &::placeholder {
        font-size: 0.75rem;
      }
    }
  }
  .exchange-rate-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .exchange-rate-input {
    display: flex;
    background-color: $slate-gray-100;
    .input-group {
      .form-control,
      .input-group-text {
        pointer-events: none;
        background-color: $slate-gray-100;
      }
    }
  }
}


//Mobile Notice
.pf-m-dashboard .payment-notices-col {
  margin-top: 1rem;
  background-color: white;
  li:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.mobile-view {
  .select-bank-opts{
    .form-check-inline{
      width: 33.33%;
      .form-check-label{
        img{
          width: auto;
          height: 1.5rem;
        }
      }
    }
  }
  .withdraw-crypto-amount {
    flex-direction: column;
    align-items: center;
    .input-group-col {
      width: 100%;
    }
    .exchange-rate-img {
      svg {
        width: 1.5rem;
        height: 1.5rem;
        transform: rotate(270deg);
      }
    }
  }
}

.transfer-duration {
  background-color: $slate-gray-100;
  color: $red-500;
  display: flex;
  align-items: center;
  justify-content: center;
  width:  -webkit-fill-available;
  margin: 0 -0.75rem -.5rem -0.75rem;
  padding: .2rem .25rem;
  border-radius: 0.25rem;
  font-size: $text-10;
  line-height: 1;
  svg {
    width: 1rem !important;
    height: 1rem !important;
    margin-right: .25rem;
  }
}

.pf-m-dashboard{
  .transfer-duration {
    font-size: $text-12;
    margin: 0rem -.2rem -.5rem -.2rem;
  }
  .transfer-duration-label {
    color: $red-500;
    font-size: $text-12;
    padding: 0rem 0.75rem;
    svg {
      width: 1rem !important;
      height: 1rem !important;
      margin-right: .25rem;
    }
  }
}