.pl-section {
  &.cny {
    .cny-paper-btn {
      text-align: center;

      .col-12,
      .col-6 {
        background-image: url("../../../images/new-year-lucky-draw/paper.png");
        background-repeat: no-repeat;
        background-position: top;
        height: 10rem;
        background-size: contain;

        .cny-label {
          padding: 2rem;
          color: #650C06;

          h5 {
            color: $red-500;
            font-size: $text-16;
          }

          p {
            font-size: $text-36;
          }

          span {
            font-size: $text-16;
          }
        }
      }

      .col-12 {
        height: 8rem;

        .cny-label {
          padding: 1.5rem;
        }
      }

    }

    .cny-flower-label {
      span {
        font-size: $text-20;
        color: $blue-600;

        &:last-child {
          color: $red-500;
        }
      }

      .col-6,
      .col-3 {
        background-image: url("../../../images/new-year-lucky-draw/cny-flower.png");
        background-repeat: no-repeat;
        background-position: top;
        height: 18rem;
        background-size: contain;

        .cny-label {
          padding: 5rem 0rem;
          color: #FDE3B7;

          p {
            font-size: $text-96;
            margin-top: -2rem;
          }
        }
      }

      .col-6 {
        height: 10rem;

        .cny-label {
          padding: 2rem 0rem;

          h5 {
            color: #FDE3B7;
            width: 50%;
            margin: 0 auto;
          }

          p {
            font-size: $text-60;
            margin-top: -1rem;
          }
        }
      }
    }
  }

  &.anni {
    .cny-paper-btn {
      text-align: center;
      position: relative;
      .deco-1,
      .deco-2,
      .deco-3{
        position: absolute;
        width: 5rem;
        height: auto;
        top:0;
        transform: translate(50%,-50%);
      }
      .deco-1{
        right: 5%;
        top:85%;
        width: 8rem;
      }
      .deco-2{
        top:50%;
        rotate: -37deg;

      }
      .deco-3{
        width: 8rem;
        right: 45%;
        top:20%;
        z-index: -1;
      }
      &.mobile{
        .deco-1{
          width: 5rem;
          left: -2%;
          top:95%;
        }
        .deco-2{
          top:14%;
          rotate: -37deg;
          z-index: -1;
          left: -7%;
  
        }
        .deco-3{
          width: 7rem;
          right: 6%;
          top:48%;
          z-index: unset;
        }
  
      }


      .col-12,
      .col-6 {
        background-image: url("../../../images/event-lucky-draw/anni/notice.webp");
        background-repeat: no-repeat;
        background-position: top;
        height: 10rem;
        background-size: contain;

        .cny-label {
          padding: 0.5rem;
          color: $slate-gray-700;

          h5 {
            color: white;
            font-size: $text-16;
            padding-bottom: 1rem;
          }

          p {
            font-size: $text-48;
          }

          span {
            font-size: $text-16;
          }
        }
      }

      .col-12 {
        height: 8rem;

        .cny-label {
          padding: 0.5rem;
          h5 {
            padding-bottom: 0rem;
          }
        }
      }

    }
    .cny-flower-label {
      .col-6,
      .col-3 {
        background-image: url("../../../images/event-lucky-draw/anni/ticket.webp");
        background-repeat: no-repeat;
        background-position: top;
        height: 14rem;
        background-size: contain;

        .cny-label {
          padding: 2rem 0rem;
          h5 {
            font-size: $text-16;
            color: $slate-gray-400;
            padding-bottom: 2rem;
          }
          p {
            font-size: $text-96;
            margin-top: -2rem;
            color: $slate-gray-700;
          }
        }
      }

      .col-6 {
        height: 10rem;

        .cny-label {
          padding:0.75rem 0rem;

          h5 {
            font-size: $text-12;
            color: $slate-gray-400;
            width: 47%;
            margin: 0 auto;
          }

          p {
            font-size: $text-60;
            margin-top: -1.5rem;
          }
        }
      }
    }

  }

  .lb-section {
    .lb-event-date {
      .date {
        width: 30%;
        justify-content: center;
      }
    }
  }

  .wc-category-cont {
    .lb-game {
      position: relative;
      color: $slate-gray-500;
      border: none;
      transition: all 0.3s ease-in-out;
      background: none;
      flex-direction: column;
      height: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 1rem 0rem;

      &.active {
        color: white;

        .icon-grey {
          display: none;
        }

        .icon-white {
          display: block;
        }

        &::after {
          background: linear-gradient($blue-400, $blue-600);
          border-radius: 4rem;
          height: 10rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
          width: 100%;
          z-index: -1;
          box-shadow: $slate-gray-shadow;
        }
      }

      h5 {
        margin-bottom: 0rem;
      }

      p {
        font-size: $text-12;

        .icon-grey {
          margin: auto;
          display: block;
        }

        .icon-white {
          margin: auto;
          display: none;
        }
      }

      .status {
        color: white;
        width: 4rem;
        text-align: center;
        border-radius: 1rem;
        margin-top: 0.2rem;

        &.deactive {
          background-color: $red-500;
        }

        &.active {
          background-color: $green-500;
        }

        &.pending {
          background-color: $gray-500;
        }
      }
    }

    .slick-container {
      position: relative;

      .slick-slider {
        .slick-list {
          overflow-y: visible;
          overflow-x: clip;
          background: linear-gradient($blue-100, #d7eafb);

          .slick-track {
            display: flex;
            justify-content: center;
            // width: 100% !important;
            border-radius: 0.5rem;
            padding: 0rem 2rem;
            margin: 2rem auto;
          }

          // .slick-slide {
          //   &:not(:last-child) {
          //     .lb-game:not(.active){
          //       border-right: rem(2px) solid $gray-400;
          //     }
          //   }
          // }
        }
      }

      .slick-arrow {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        // padding: 2rem 0;
        .slick-arrow-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $slate-gray-300;
          width: 2rem;
          cursor: pointer;
          transition: all ease-in-out 0.3s;
          pointer-events: all;

          &.next {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.prev {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          svg {
            width: 3rem;
            height: 3rem;
            color: white;
          }

          &:hover {
            box-shadow: $slate-gray-hover-shadow;
          }
        }
      }
    }
  }

  .wc-vs-section {
    background-color: white;
    padding: 2rem 4rem;
    text-align: center;
    border-radius: 1rem;
    box-shadow: $slate-gray-card-shadow;

    &.wc-table {
      .wc-head {
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .wc-body {
        width: 25%;
        height: auto;
      }

      // .wc-bg {
      //   position: relative;
      // }
      .wc-content {
        //   position: absolute;
        //   top: 0.5rem;
        //   left: 0;
        height: auto;
        //   text-align: center;
        width: 100%;
        // background: linear-gradient($blue-100, #D7EAFB);
        background-image: url(../../../images/world-cup/premier-league-bg.png);
        object-fit: cover;
        background-repeat: no-repeat;
        // background-position: bottom;
        border-radius: 0.5rem;
        background-size: cover;
        position: relative;
      }

      .wc-time {
        position: relative;
        z-index: 5;
        background: linear-gradient($blue-400, $blue-600);
        margin: 0rem 0.5rem;
        padding: 0.5rem;
        border-radius: 2rem;
        color: white;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }

      .wc-details {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        padding: 1rem;

        .form-check {
          position: relative;
          margin: 0rem;

          .win-img {
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: -0.1rem;
            right: -0.5rem;
            object-fit: cover;
          }

          &.enable {
            background: linear-gradient(to bottom, #b3c9fc, #eef5ff 50%);
            border-radius: 0.5rem;
          }

          .btn-check {

            &:active,
            &:focus {
              &+.form-check-label {
                box-shadow: $blue-shadow !important;
              }
            }

            &:checked {
              &+.form-check-label {
                box-shadow: $blue-shadow !important;
                color: $slate-gray-800 !important;
                background: white;
                border: rem(3px) solid $blue-600 !important;
              }
            }
          }
        }

        .form-check-label {
          max-width: unset;
          height: 4rem;
          background-color: rgba(white, 0.3);
          border-radius: 0.5rem;
          border: unset;
          box-shadow: $blue-shadow;
          transition: all .2s ease-in-out;
          padding: 0.5rem;

          &::after {
            content: none;
          }

          img {
            width: 2.5rem;
            margin-right: 0.5rem;
          }

          &.disabled {
            background-color: $gray-400;
            opacity: 0.5;
          }
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          margin-right: unset;
        }

        .versus-img {
          width: 4rem;
          height: auto;
        }
      }

      .score-wrap {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem 1rem 1rem;
        border-top: rem(2px) solid white;

        .score-body {
          display: flex;
          flex-direction: column;
        }

        .btn-white {
          font-weight: 600;
          height: 2rem;
          padding: 0.5rem 2rem;
          border-radius: 0.5rem;
          box-shadow: $slate-gray-shadow;
          width: 100%;
        }

        .score-font {
          font-size: $text-16;
          font-weight: 600;
        }

        .aet-font {
          color: $red-500;
          text-transform: uppercase;
        }
      }

      .wc-notice {
        img {
          width: 50%;
        }

        h1 {
          margin-bottom: 1rem;
        }
      }
    }

    .btn-gradient-blue {
      border: unset;
      height: 3rem;
      width: 50%;
    }
  }

  .wc-reward {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;

    .wc-reward-container {
      border-radius: 1rem;
      background: linear-gradient(to bottom, #fbfcff, #eef5ff 30%);
      box-shadow: $slate-gray-card-shadow;
      color: $slate-gray-500;
      opacity: 0.8;
      text-align: center;
      padding: 0rem 0rem 1rem 0rem;

      &.col-3 {
        width: 20%;
      }

      .wc-reward-header {
        background-color: white;
        padding: 1rem;
        width: 100%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      &.active {
        box-shadow: 0 0px 10px 0 rgba($blue-500, 0.7);
      }

      &.inactive {
        opacity: 0.5;
      }
    }
  }
}

.wc-modal {
  .modal-content {
    background: linear-gradient(to bottom, #b3c9fc, white 30%) !important;
    position: relative;

    .modal-body {
      padding: 3rem;
    }
  }

  .btn-gradient-blue {
    width: 60%;
  }
}

.mobile-view {
  .pl-section {
    margin: 0rem 1rem;

    .lb-section {
      .lb-event-date {
        .date {
          width: 100%;
        }
      }
    }

    .slick-container {
      height: 6rem;

      .slick-slider {
        padding: 0rem 1.5rem;

        .slick-list {
          .slick-track {
            padding: 0;

            .slick-slide {
              width: rem(80px) !important;
            }
          }
        }
      }

      .slick-arrow {
        .slick-arrow-btn {
          width: 1.5rem;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .wc-category-cont {
      .match-date-btn {
        .lb-game {
          height: auto;

          &.active::after {
            height: 7rem;
          }
        }
      }
    }

    .wc-vs-section {
      border-radius: 1rem;
      padding: 0.5rem;

      .wc-head {
        margin: 0.5rem;
        justify-content: space-between;
      }

      .versus-img {
        width: 3rem !important;
      }

      &.wc-table {
        .wc-body {
          width: 48%;
          padding: 0rem;

          .wc-content {
            top: 0.2rem;

            .wc-time {
              border-radius: 2rem;
              padding: 0.25rem;
              border-top-left-radius: unset;
              border-top-right-radius: unset;
            }

            .wc-details {
              padding: 1rem;
            }

            .score-wrap {
              padding: 0.5rem;

              span {
                font-size: $text-12;
              }

              .btn-white {
                padding: 0.5rem;
              }

              .score-font {
                font-weight: 600;
                font-size: $text-14;
              }
            }
          }
        }

        //
        .wc-bg {
          img {
            width: 100%;
            height: auto;
          }
        }

        .form-check {
          .win-img {
            width: 2.5rem;
            height: 2.5rem;
            top: 0rem;
            right: -0.5rem;
          }

          .form-check-label {
            width: unset;
            min-width: 100%;
            height: 3.5rem;

            img {
              width: 2.5rem;
            }
          }
        }

        .wc-notice {
          img {
            width: 100%;
          }
        }
      }
    }

    .wc-reward {
      padding: unset;

      .wc-reward-container {
        padding-bottom: 0.5rem;
        border-radius: 0.5rem;

        .wc-reward-header {
          padding: 0.5rem;
        }

        &.col-3 {
          width: 25%;
        }

        .wc-reward-body {
          font-size: $text-10;
        }
      }
    }

    .section-header-gray {
      h1 {
        color: $slate-gray-400;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      h5 {
        color: $gray-500;
      }
    }

    .input-group-col {
      .input-group {
        .form-control {
          text-align: left;
          border-right: 0;
        }

        .input-group-text {
          background-color: white;
        }
      }
    }
  }

  .lb-event-date {
    justify-content: space-between;

    .date {
      justify-content: center;
      width: 100%;

      .status {
        color: white;
        width: 4rem;
        text-align: center;
        border-radius: 1rem;
        margin-top: unset;
        margin-left: 0.5rem;
        font-size: $text-12;

        &.deactive {
          background-color: $red-500;
        }

        &.active {
          background-color: $green-500;
        }

        &.pending {
          background-color: $gray-500;
        }
      }
    }
  }

  .wc-modal {
    .modal-content {
      .modal-body {
        padding: 3rem 0rem 0rem 0rem;
      }
    }
  }
}